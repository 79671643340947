import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'

import { api_config } from "../components/api_url";
import { useNavigate } from 'react-router-dom';


import MainLogoStatic from '../components/MainLogoStatic'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import TsParticles from "../components/ParticlesBackground";

const AccountVerify = () => {
    //Import API URL
    const api_url = api_config.url.API_URL
    
    const [ActivationStatus, setActivationStatus] = useState("");
    
    const navigate = useNavigate();

    const search = useLocation().search;
    const code = new URLSearchParams(search).get('code');

    useEffect(() => {
        //Include Google Analytics Tag
      const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
      // Google Analytics tracking code
      const head = document.querySelector("head");
      const script1 = document.createElement("script");
      script1.async = true;
      script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
      head.appendChild(script1);
      const script2 = document.createElement("script");
      script2.innerText = 
        `window.dataLayer = window.dataLayer || [];\
          function gtag(){dataLayer.push(arguments);}\
          gtag('js', new Date()); \
          gtag('config', '${trackingID}');\
          gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
      head.appendChild(script2);
        
        // Update the document's meta tags when the component mounts
        document.title = 'Open Mortality | Verify Your Email';
        document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - Verify Your Email');
    
        // Clean up the meta tag modifications when the component unmounts
        return () => {
        document.title = '';
        document.querySelector('meta[name="description"]').setAttribute('content', '');
        head.removeChild(script1);
        head.removeChild(script2);
        };
    }, []);
    
    const handleLogin = () => {
        navigate("/login")
      }

    useEffect(() => {
        setActivationStatus("");
        //console.log(api_url + '/user/accountVerify?code=' + code)
        fetch(api_url + '/user/accountVerify?code=' + code, { 
            method: 'GET' ,
            credentials: 'include',                
                headers: { 
                    'Content-Type': 'application/json' 
                }            
            })
            .then((res) => res.json())
            .then((data) => {
                //console.log(data);
                if (!data.success) setActivationStatus(data.info.message);
                else {
                    setActivationStatus(data.info.message);                    
                }
                //Delay for few seconds and redirect to login page (Production ONLY)
                if (process.env.NODE_ENV !== "development" )
                {
                    const delay_time = 8 * 1000
                
                    setTimeout(() => {
                        navigate("/login")
                    }, delay_time)
                }
                
            })
            .catch((err) => {
                //console.log(err)
            });

    }, [])

    return (
        <>  
        <TsParticles />
      
      
      <Row className='d-flex justify-content-center'>
        <Col className='p-3' style={{ zIndex : 4}}>
          <MainLogoStatic></MainLogoStatic>      
        </Col>
        
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className='mt-5 d-flex justify-content-center align-items-center' style={{ zIndex : 2}}>
          <FontAwesomeIcon size="3x" icon={icon({name: 'circle-info'})} />
        </Col>

        <Col xs={9} sm={8} md={6} lg={6} xl={6} className='ms-2 me-2 mt-3 text-center' style={{ zIndex : 2}}>
            <ReactMarkdown remarkPlugins={[remarkGfm]} children={ActivationStatus} />
        </Col>
        
        <Col xs={9} sm={8} md={6} lg={6} xl={6} style={{ zIndex : 2}} className="mt-3">
          <div className='d-grid justify-content-sm-center'>
            <Button className="om-button black-gradient-hover-effect" variant="dark" onClick={handleLogin}>Login</Button>
          </div>
        </Col>      
      
        </Row>
            
        </>
    )
}

export default AccountVerify