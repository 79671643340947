import React from 'react'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import UOfTLogo from '../Assets/images/logo-uoft-resized.png'
import StMichaelsUTLogo from '../Assets/images/logo-uht-resized.png'
import CghrLogo from '../Assets/images/logo-cghr-resized.png'

import './SubFooter.css';

const Footer = () => {
    return (
      <>
        <Row className="mt-1 mb-1 justify-content-center fade-in">
            <Col sm={12} md={5} lg={3} xl={3} className='d-flex align-items-center justify-content-center' >
              <a href='https://www.utoronto.ca/' target="_blank" rel="noreferrer">
                <img className="sub-footer-uoft-logo" src={UOfTLogo} alt="university of Toronto"/>
              </a>              
            </Col>            
            
            <Col sm={12} md={5} lg={3} xl={3} className='d-flex align-items-center justify-content-center'>
              <a href='https://unityhealth.to/' target="_blank" rel="noreferrer">                
                <img className="sub-footer-uht-logo" src={StMichaelsUTLogo} alt="St Michael's UnityHealth Logo" />                
              </a>              
            </Col>

            <Col sm={12} md={10} lg={3} xl={3} className='d-flex align-items-center justify-content-center mt-md-3'>
              <a href='https://www.cghr.org/' target="_blank" rel="noreferrer">                
                <img className='sub-footer-cghr-logo' src={CghrLogo} alt="Centre for Global Health Research" />
              </a>              
            </Col>            
        </Row>        
      </>
    )
}

export default Footer