import React, {useEffect, useState, useMemo} from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { createBrowserHistory } from "history";
import axios from 'axios';

import {Tabs, Tab, Button, Col, Row, Card, Stack} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import DataInfo from '../components/DataInfo';
import Displaydatadict from '../components/DisplayDatadict';
import DisplayDataPreview from '../components/DisplayDataPreview';
import DisplayMapDataPreview from '../components/DisplayMapDataPreview';
import HeaderNew from '../components/HeaderNew';

import { api_config } from "../components/api_url";
import { collapseToast, toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
//import SearchResultHeader from '../components/SearchResultHeader';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { useSpring, animated } from '@react-spring/web';

const ViewDatasetDetails = () => {
  
  const useMemoizedQueryParams = () => {
    const location = useLocation();  
    // This will only be recalculated when the location object changes (query params change)
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);  
    return queryParams;
  };

  const queryParams = useMemoizedQueryParams();

  const api_url = api_config.url.API_URL

  let localCart = localStorage.getItem("cart");
  const loggedInUser = localStorage.getItem("user");
   
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();     

  let history = createBrowserHistory();

  const page = parseInt(queryParams.get('page')) > 0 ? queryParams.get('page') : 1;
  const pageSize = parseInt(queryParams.get('pageSize')) > 0 ? queryParams.get('pageSize') : 10;
  const climit = parseInt(queryParams.get('climit')) > 0 ? queryParams.get('climit') : 5;
  const queryString = !!queryParams.get('query') ? queryParams.get('query') : "";

  //const [key, setKey] = useState(params.tabname ? params.tabname : 'variables'); 
  //const [spatialdata, setspatialdata] = useState({});
  const [cart, setCart] = useState([]);  
  const [dsdInfo, setDsdInfo] = useState([]);  
  
  const [dataDict, setDataDict] = useState([]);  
  const [data, setData] = useState([]);  
  const [geoJsonData, setGeoJsonData] = useState([]);  
  const [accessTokenResult, setAccessTokenResult] = useState();   
  const [hasMore, sethasMore] = useState();
  
  const [isLoading, setIsLoading] = useState(true);

  const initialQuery = {
    combinator: 'and',
    rules: []
  }

  const [query, setQuery] = useState(initialQuery);
  const [isCaseSensitive, setIsCaseSensitive] = useState(false);
    
  /*
  const handleClearQuery = () => {
    setQuery(initialQuery)
    navigate("/data/" + params.data_name  +"?pageSize=" + pageSize + "&climit=" + climit+ "&page="+ parseInt(page), {
      state: {        
        data: location.state ? location.state.data : "",
        dataset: location.state ? location.state.dataset : "",
        user : location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))                
      },
    })
  }*/

  //Go back to previous page when no record was found by the query
  const handleGoBack = () => {    
    //console.log(data.status, location.pathname + location.search.split("&query=")[0])  
    if (data.status)
    {
      navigate(-1, {
        state: {        
          data: location.state ? location.state.data : "",
          dataset: location.state ? location.state.dataset : "",
          user : location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))                
        },
      })
    }
    else {
      navigate(location.pathname + location.search.split("&query=")[0], {
        state: {        
          data: location.state ? location.state.data : "",
          dataset: location.state ? location.state.dataset : "",
          user : location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))                
        },
      })
    }
  }

  //Set metadata
  useEffect(() => {
    //Include Google Analytics Tag
    const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
    // Google Analytics tracking code
    const head = document.querySelector("head");
    const script1 = document.createElement("script");
    script1.async = true;
    script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
    head.appendChild(script1);
    
    const script2 = document.createElement("script");
    script2.innerText = 
        `window.dataLayer = window.dataLayer || [];\
        function gtag(){dataLayer.push(arguments);}\
        gtag('js', new Date()); \
        gtag('config', '${trackingID}');\
        gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
    head.appendChild(script2);
    
    // Update the document's meta tags when the component mounts
    document.title = 'Open Mortality | ' + params.data_name;
    document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality | ' + params.data_name);

    // Clean up the meta tag modifications when the component unmounts
    return () => {
      document.title = '';
      document.querySelector('meta[name="description"]').setAttribute('content', '');
      head.removeChild(script1);
      head.removeChild(script2);
    };
  }, []);

  const updateCart = item => {      
      //create a copy of our cart state, avoid overwritting existing state    
      let cartCopy = [...cart];        
      //assuming we have an ID field in our item
      let {ID} = item;        
      //look for item in cart array
      let existingItem = cartCopy.find(cartItem => cartItem.ID === ID);
      //if item already exists            
      if (existingItem) {
        cartCopy.splice(cartCopy.indexOf(existingItem),1);   
        toast(<div className="text-center align-items-center"><FontAwesomeIcon size="1x" icon={icon({name: 'circle-minus'})} />
        <span> Removed <strong>{existingItem.supp_info.data_name}</strong></span></div> )
      }      
      else if (cartCopy.length >= 10) {        
        toast(<div className="text-center align-items-center" style={{ color : "red"}}><FontAwesomeIcon size="1x" icon={icon({name: 'triangle-exclamation'})} />
        <span> Unable to add <strong>{item.supp_info.data_name}</strong><br />
        You have reached the maximum limit of 10 items per download. 
        To proceed, please download the existing 10 items in your cart first. 
        If you wish to add a new item, kindly remove an item from your cart and add the new one. </span></div> )
      }
      else 
      { //if item doesn't exist, add it
        cartCopy.push(item);
        toast(<div className="text-center align-items-center" ><FontAwesomeIcon size="1x" icon={icon({name: 'circle-plus'})} />
        <span> Added <strong>{item.supp_info.data_name}</strong> </span></div> )
      }      
      //update app state
      setCart(cartCopy)
      //make cart a string and store in local space
      let stringCart = JSON.stringify(cartCopy);
      localStorage.setItem("cart", stringCart)    
  }
  
  //Convert cart content from local storage to state varible
  useEffect(() => {    
    //turn the cart content in local storage into js
    localCart = JSON.parse(localCart);
    //load persisted cart into state if it exists
    if (localCart) setCart(localCart)    
    //Get all the data under the dataset    
  }, []);
  
  const handleReLogin = () => {
    fetch(api_url +"/user/logout", {      
      method: 'POST',
      credentials: "include",
      headers: { 
        'Content-Type': 'application/json' 
      }        
    })
    .then((res) => res.json())
    .then( () => {            
      //redirect to login page
      navigate('/Login')
    })
    .catch((err) => {            
      return;
    });
  }

  //Go back to view all the data in dataset
  const ViewDataset = (data_abbr) => {    
    navigate("/dataset/" + data_abbr , { state : 
        {
            dataset : location.state.dataset.length > 0 ? location.state.dataset : dsdInfo.dataset,
            data :  Object.keys(location.state.data).includes('allowed_access') ? location.state.data : dsdInfo.data,
            user : localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ""
        }
    });
  }  
  
  //Prevent adding duplicated history when re-render
  /*useEffect(() => {      
    //console.log(`${location.pathname}${location.search}` , `${history.location.pathname}${history.location.search}`)

    //if (`${location.pathname}${location.search}` === `${history.location.pathname}${history.location.search}`)
    //{
    //  history.replace(`${location.pathname}${location.search}`);
    //}
    //if (location.pathname === history.location.pathname) 
    //{      
      //history.replace(location.pathname);
      // Replace the current URL without adding a new entry to the history
      //history.replace(`${location.pathname}${location.search}`);
    //}
      
  }, [history])*/ 
  
  //Get Dataset and Data Information
  useEffect(() => {
    const getDsDInfo = async () => {
      try {
        const datainfo = await getDataInfoByDataName();
        const datasetinfo = await getDatasetInfoByDataName();
        
                  
        localStorage.setItem("isSpatial", datainfo.is_spatial);
        setDsdInfo({dataset : datasetinfo, data: datainfo})
        
      } catch (error) {
        // handle error here, e.g. display an error message to the user
        console.error("Error fetching data", error);
      }
      
    }
    
    const fetchDataInfo = async () => {      
      if (
        !( !!location.state && Object.keys(location.state).includes('data') && Object.keys(location.state.data).includes('allowed_access')) || 
        !( !!location.state && Object.keys(location.state).includes('dataset') && location.state.dataset.length > 0)
      ) {      
        getDsDInfo();
      } else {
        localStorage.setItem("isSpatial", location.state.data.is_spatial);
      }
    }
  
    fetchDataInfo();
  }, []);
  
  //Call refresh token before access to any resources  
  const refreshToken = () => {    
    var cur_user = JSON.parse(loggedInUser);    
    return new Promise(async (resolve) => {        
        fetch(api_url + "/user/refresh", {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'                    
                }
        })
        .then((res) => res.json())
        .then((myJson) => {            
            if (myJson.refresh)
            {
              cur_user['token'] = myJson.token;
              localStorage.setItem('user', JSON.stringify(cur_user));
            } 
            resolve(myJson)
        });
    })    
  }

  /* Get Data Info*/
  const getDataInfoByDataName = async () => {       
    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/data/" + params.data_name + "/metadata", {        
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => res.json())
      .then(function (myJson) {
        //console.log(myJson.data);        
        resolve(myJson.data);
      });
    });
  };
  
  /* Get Dataset Info*/
  const getDatasetInfoByDataName = async () => {             
    return new Promise(async (resolve, reject) => {
      setIsLoading(true)
      fetch(api_url + "/data/" + params.data_name + "/datasets", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"          
        },
      })
      .then((res) => res.json())
      .then(function (myJson) {
          resolve(myJson.data);
      });
    });
  };
    
  //Get data
  const getData = async (accessToken) => {
    setIsLoading(true)
    return new Promise(async (resolve) => {      
        const q = !!queryString ? "&query=" + queryString : ""
        //console.log("page size", page_size, "page", page, "column limit", column_limit)        
        fetch(api_url + "/data/" + params.data_name + "?pageSize=" + pageSize + "&page=" + page + "&climit=" + climit + q, {        
            signal: AbortSignal.timeout(8000),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                Authorization: "Bearer " + accessToken
            }
        })
        .then(function (response) {
          return response.json();
        })
        .then(function (myJson) {
          Object.keys(myJson).includes('hasMore') ? sethasMore(myJson.hasMore) : sethasMore(false)
          setIsLoading(false)
          resolve(myJson)                    
        })
        .catch( (err) => {
          //console.log( err.name )
          setIsLoading(false)
          resolve({ status : false})
        })
    })
}
     
  //Get datadict
  const getDatadict = async (accessToken) => {    
    var responseClone;
    setIsLoading(true)
    return new Promise(async (resolve, reject) => {
      try {
        fetch(api_url + "/data/" + params.data_name + "/variables", {
          method: "GET",
          credentials: "include",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: "Bearer " + accessToken
          }
        })
        .then((res) => {
          responseClone = res.clone(); 
          return res.json()
        })
        .then(function (myJson) {          
          setIsLoading(false)          
          resolve(myJson)        
        }, function (rejectionReason) { //try reject handling
          responseClone.text()
          .then(function (bodyText) {
              console.log('Received the following instead of valid JSON:', bodyText);
          });
      });
      } catch(err)      
      {
        reject(err)
      }
    })  
  }

  //Get Data dict
  useEffect(() => {
      const getDatadictFromAPI = async () => {
        const refreshAccessTokenResult = await refreshToken()         
        const dd = await getDatadict(refreshAccessTokenResult.token);
        setDataDict(dd)
    }
    
    getDatadictFromAPI()
  }, []); 

  //Get Data 
  useEffect(() => {
    const getDataFromAPI = async () => {
      setData([])
      
      //Refresh access token
      const refreshAccessTokenResult = await refreshToken()        
      
      setAccessTokenResult(refreshAccessTokenResult);

      const d = await getData(refreshAccessTokenResult.token);
      if (d.type === "json")        
        setData(d)
      else if (d.type === "geojson")
        setGeoJsonData(d)
      else {
        setData(d)
        setDataDict(d)
      }        
    }
    
    getDataFromAPI()
  }, [pageSize, page, climit, queryString]); 

  useEffect( ()=> {
      let tempJson = {}      
      
      if (Object.keys(geoJsonData).includes("data") && Object.keys(geoJsonData).includes("totalRecordCount"))
      {   
        if (geoJsonData.recordCount > 0) //If there is record returned
        {
          //converting geojson -> json"
          const nonSpatialData = geoJsonData.data.features.map(feature => feature.properties);
          tempJson = { ...geoJsonData }
          tempJson.type = "json"
          tempJson.data = nonSpatialData          
        }
        else //If there is no feature returned; return a json with no record
        {          
          tempJson = { ...geoJsonData }
          tempJson.type = "json"
          tempJson.data = []          
        }
      setData(tempJson)        
      }      
  },[geoJsonData])
    
  const props = useSpring({
    from: { x : 50, y : 0, opacity: 0.5 },
    to: { x : 0, y: 0, opacity : 1 },    
    config: {
      mass: 5,
      friction: 50,
      tension: 270,
      precision: 0.0001,
    },
  })

  const DataDetails = () => {    
    if ( 
        ((!!location.state && Object.keys(location.state).includes('data') && Object.keys(location.state.data).includes('allowed_access')) &&
        (!!location.state && Object.keys(location.state).includes('dataset') && (location.state.dataset.length > 0))) ||
        ((Object.keys(dsdInfo).includes('data') && Object.keys(dsdInfo.data).includes('allowed_access')) &&
        (Object.keys(dsdInfo).includes('dataset') && (dsdInfo.dataset.length > 0))) 
      ) {
      return(
        <>
        <div className='m-0 p-1'>
        <Row className='mt-0'>
          <Col xs={3} sm={3} md={6} lg={2} xl={2}>
            <strong>Published:</strong>
          </Col>
          <Col xs={9} sm={9} md={6} lg={10} xl={10}>
            { !!location.state && Object.keys(location.state).includes('data') && Object.keys(location.state.data).includes('publish_date') ? location.state.data.publish_date.slice(0,10) : dsdInfo.data.publish_date.slice(0,10)}
          </Col>
        </Row>

        <Row className='mt-1'>
          <Col xs={3} sm={3} md={6} lg={2} xl={2}>
            <strong>Contact:</strong>
          </Col>
          
          <Col xs={9} sm={9} md={6} lg={10} xl={10}>
          <span> <a href={!!location.state && Object.keys(location.state).includes('data') && Object.keys(location.state.data).includes('contact') 
            ? `mailto:${location.state.data.contact}` 
            : `mailto:${dsdInfo.data.contact}`
            } >
            { !!location.state && Object.keys(location.state).includes('data') && Object.keys(location.state.data).includes('contact') ? location.state.data.contact : dsdInfo.data.contact}
            </a>
            </span>
          </Col>
        </Row>
        
        <Row className='mt-1'>
          <Col xs={3} sm={3} md={6} lg={2} xl={2}>
            <strong>Dataset:</strong> 
          </Col>
          <Col xs={9} sm={9} md={6} lg={10} xl={10}>
            {
              ( (!!location.state && Object.keys(location.state).includes('dataset') && location.state.dataset.length > 0) ? location.state.dataset : dsdInfo.dataset).map( (ds) => {
                return(                  
                  <Button 
                    size="sm" 
                    key={ds.dataset_id} 
                    className="pt-0 pb-0 ps-1 pe-1" 
                    variant="outline-dark" 
                    style={{cursor: "pointer"}} 
                    onClick={() => ViewDataset(ds ? ds.title_abbr : "Error")}
                  >
                    {ds ? ds.title : "Error"}
                  </Button>
                )
              })
            }          
          </Col>
        </Row>

        <Row className='mt-1'>
          <Col xs={3} sm={3} md={6} lg={2} xl={2}>
            <strong>Tags:</strong> 
          </Col>
          <Col xs={9} sm={9} md={6} lg={10} xl={10} className='d-flex justify-content-start'>
            {            
              ( !!location.state && Object.keys(location.state).includes('data') && Object.keys(location.state.data).includes('tag') ? location.state.data.tag : dsdInfo.data.tag).split(",").map( (t, index) => {
                return (<Badge className="me-2 mt-1 ps-2 pe-2" key={t + "_" + index} bg="dark">{t}</Badge>)
              })
            }          
          </Col>
        </Row>
        </div>        
        </>
      )
    }
    else {
      return(
        <>
          <Row className='mt-5'>          
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex justify-content-center'>
            <div>
                There is error on retrieving data information
            </div>
            </Col>
          </Row>
        </>
        )
    }
  }
  return (    
    <>      
      <HeaderNew CartCount={cart ? cart.length : 0} />      
      
      <Row className='justify-content-center'>
        <Col className='mt-4' xs={10} sm={10} md={10} lg={8} xl={7}>
          <DataInfo dsdinfo={ !!location.state ? location.state : dsdInfo} session={accessTokenResult} updateCart={updateCart} cart={cart} ></DataInfo>                    
        </Col>
      </Row>

      <Row className='justify-content-center'>
        <Col className='p-0' xs={10} sm={10} md={10} lg={8} xl={7}>
          
          {            
            ( () => {              
              //console.log(dataDict, data, geoJsonData, accessTokenResult)
              
              if  (Object.keys(dataDict).includes("status") && 
                  ((Object.keys(data).includes("status") && Object.keys(geoJsonData).includes("status")) ||
                  (Object.keys(data).includes("status") && !Object.keys(geoJsonData).includes("status")))
                  )
                { 
                if (loggedInUser === null)
                { 
                  //User Not login
                  return(
                    <>
                      <div className="d-flex justify-content-center">
                      <Card className='info-display-card'>
                        <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                            <Stack >
                                <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} />
                                <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-4 mb-3' children="Please login to view the data"></ReactMarkdown>
                            </Stack>
                            <Button className="om-button black-gradient-hover-effect" variant='dark' onClick={handleReLogin}>Login</Button>
                        </Card.Body>
                      </Card>
                      </div>
                    </>
                  )
                }
                else if (!data.status && !data.data) {   
                  //Query timeout or query error
                  return(
                    <>
                    <div className="d-flex justify-content-center">
                      <Card className='info-display-card'>
                          <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                              <Stack>
                                  <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} />
                                  <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>There is problem on the query or query timeout </ReactMarkdown>
                                  <Row className="d-flex justify-content-center">
                                    {/*<Col>
                                      <Button variant='dark' className='om-button' onClick={handleClearQuery}>Clear query</Button>
                                    </Col>*/}
                                    <Col>
                                      <Button variant='dark' className='om-button' onClick={handleGoBack}>Go back</Button>
                                    </Col>
                                  </Row>
                              </Stack>
                          </Card.Body>
                      </Card>
                    </div>                
                    </>
                  )
                }
                else if (loggedInUser && accessTokenResult.status && !accessTokenResult.token) {                  
                  //Session timeout
                  return(
                    <>
                      <div className="d-flex justify-content-center">
                      <Card className='info-display-card'>
                        <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                            <Stack>
                                <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} />
                                <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2' children={accessTokenResult.message}></ReactMarkdown>                            
                            </Stack>
                            <Button className="om-button black-gradient-hover-effect" variant='dark' onClick={handleReLogin}>Login</Button>
                        </Card.Body>
                      </Card>
                      </div>
                    </>
                  )
                }                
                else if (loggedInUser && dataDict.status && !dataDict.permission) {
                  //Permission denied
                  return(
                    <>
                    <div className="d-flex justify-content-center">
                      <Card className='info-display-card'>
                          <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                              <Stack>
                                  <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} />
                                  <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>{dataDict.message}</ReactMarkdown>
                                  <Button variant='link' href='mailto:support@openmortality.org' style={{ textDecoration : "none"}}>                                                    
                                    <FontAwesomeIcon size="lg" icon={icon({name: 'envelope', style:"regular"})} /> support@openmortality.org
                                  </Button>
                              </Stack>
                          </Card.Body>
                      </Card>
                    </div>                
                    </>
                    )
                }
                else if (data.status && loggedInUser && dataDict.token && dataDict.permission && data.data.length === 0) {
                    //No record found by the query                    
                    return(
                      <>
                      <div className="d-flex justify-content-center">
                        <Card className='info-display-card'>
                            <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                                <Stack>
                                    <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} />
                                    <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>No record found for the query</ReactMarkdown>
                                    <Row className="d-flex justify-content-center">
                                      {/*<Col>
                                        <Button variant='dark' className='om-button' onClick={handleClearQuery}>Clear query</Button>
                                      </Col>*/}
                                      <Col>
                                        <Button variant='dark' className='om-button' onClick={handleGoBack}>Go back</Button>
                                      </Col>
                                    </Row>
                                </Stack>
                            </Card.Body>
                        </Card>
                      </div>                
                      </>
                    )
                }                         
                //else if (data.status && dataDict.permission && dataDict.token) {         
                else if (data.status && loggedInUser && dataDict.token && dataDict.permission && data.data.length > 0 ){
                    return(
                      <animated.div style={{...props}} >
                      <Tabs                
                        defaultActiveKey="preview"
                        id="uncontrolled-data-detail-tab"
                        className="m-1"
                        mountOnEnter={true} 
                        animation="true" 
                        unmountOnExit={true}
                      >
                        <Tab className="m-1" eventKey="preview" title="Preview">
                          <DisplayDataPreview 
                            jsonData={data} 
                            dataDict={dataDict} 
                            query={query} 
                            setQuery={setQuery} 
                            queryString={queryString} 
                            setIsCaseSensitive={setIsCaseSensitive}
                            isCaseSensitive={isCaseSensitive}
                          />
                        </Tab>
                        <Tab className="m-1" eventKey="dictionary" title="Dictionary">                        
                          <Displaydatadict dataDict={dataDict}/>
                        </Tab>                        
                        {                          
                          Object.keys(geoJsonData).includes("data")
                          ? (<Tab className='m-2' eventKey="map" title="Map">                                
                              <DisplayMapDataPreview geoJSONData={geoJsonData}/>
                            </Tab> )
                          : <></>
                        }
      
                        <Tab className="m-1" eventKey="details" title="Details">
                          <DataDetails />      
                        </Tab>
                    </Tabs>
      
                    </animated.div>
                    )
                }
                else
                {
                  <><div>Error</div></>
                }
              }
              else if(isLoading)
              {                
                return (
                  <div className="d-flex justify-content-center" style={{ marginTop : "50px"}}>
                        <Spinner animation="border" role="status" >
                        <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )
              }
              else
              {
                <><div>Error</div></>
              }
            }) ()
          }
        </Col>
      </Row>      
    </> 
  )
}

export default ViewDatasetDetails