import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Col, Row, Card, Stack, Button} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import { api_config } from "./api_url";

import { useSpring, animated } from '@react-spring/web';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { DATA_MD_ABOUT_EXTRACT_REGEX, DATA_MD_EXTRACT_ITALIC_REGEX, DATA_MD_EXTRACT_LINK_REGEX } from '../constants/regex';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import "./ListDataset.css"

const ListDatasets = () => {    
    
    //const searchParams = new URLSearchParams(location.search);
    const api_url = api_config.url.API_URL
    const [datasets, setDatasets] = useState([]);
    
    const [keyword, setKeyword] = useState('');    

    //const [mdDsDescription, setmdDsDescription] = useState("");       

    const [isLoading , setIsLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();
    
    const handleLogoClick = async () => {
        navigate("/")
    }

    const handleViewDataset = (dataset) => {
        //force to convert dataset info to a array in order to cater a data may exist in multiple dataset when doing a reverse query
        const ds = [];
        ds.push(dataset)
        
        //Passing dataset name using state
        navigate("/dataset/" + dataset.title_abbr.toLowerCase(), {
            state: {
                dataset : ds                
            }
        });
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const keyword = searchParams.get("keyword");
        
        setKeyword(keyword || "");
                
        const goSearch = async (keyword) => {
            if (keyword) await datasetSearch2(keyword)
            else setDatasets({ data : [] , message : "Please input keyword for dataset searching"})
        }
        
        if (keyword) goSearch(keyword)
        else listAlldata()
    }, [location.search]);

    const listAlldata = async () => {
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/dataset", {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((response) => {                
                return response.json();
            })
            .then((myJson) => {
                resolve(myJson);           
                setIsLoading(false)     
                if ((!!myJson.data) && (myJson.data.length > 0 ))
                    setDatasets(myJson.data);
                else if (!!myJson.message)
                    setDatasets(myJson); 
                else               
                    setDatasets(""); 
                //setKeywordDisplay(keywd);
            });
        });
    }

    const datasetSearch2 = async (keywd) => {
        return new Promise(async (resolve, reject) => {
            setIsLoading(true)
            fetch(api_url + "/dataset/search?keyword=" + encodeURIComponent(keywd), {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((response) => {                
                return response.json();
            })
            .then((myJson) => {
                resolve(myJson);      
                setIsLoading(false)               
                if (myJson.data.length > 0 )
                {
                    setDatasets(myJson.data);
                    
                }
                else
                {
                    setDatasets(myJson);                       
                }
                //setKeywordDisplay(keywd);
            });
        });
                
    };

    const props = useSpring({
        from: { x : 0, y : -100, opacity: 0.1 },
        to: { x : 0, y: 0, opacity : 1 },    
        config: {
          mass: 5,
          friction: 70,
          tension: 270,
          precision: 0.0001,   
          delay : 1       
        },
    })
    
    return (        
        <>  
            <Row className="ms-1 me-1 mt-4 mb-3 justify-content-center" >
                <Col xs={11} sm={11} md={10} lg={8} xl={7} > 
                {                    
                    ((Object.keys(datasets).length > 0) && (!Object.keys(datasets).includes('message')) && (!Object.keys(datasets).includes('err')))
                    ? datasets.map((dataset) => {                        
                        return(
                            <animated.div key={dataset.dataset_id} style={{...props}}>
                            
                            <Card className="data-listing-card shadow-sm" >
                            {/* Card rendering for markdown content*/}                                
                            <Card.Body className="data-listing-card-body" >
                                <Card.Title className='m-0 pb-2 d-flex text-start data-listing-card-title'>
                                    
                                    <div className='me-1 mb-1 d-flex align-self-end' >
                                        <span>{dataset.title ? dataset.title : ""} ({dataset.title_abbr ? (dataset.title_abbr).toUpperCase() : ""})</span>
                                    </div>
                                        
                                    {/*<div className='d-flex align-self-end me-1 mb-1' >
                                        
                                </div>*/}
                                    
                                </Card.Title>
                                
                                <Card.Text className='m-0 pb-2' style={{ color : "#6c757d"}}>                                
                                    <FontAwesomeIcon size="1x" icon={icon({name: 'calendar', style:'regular'})} />
                                    <span> {dataset.publish_date ? dataset.publish_date.slice(0,10) : ""}</span>
                                </Card.Text>

                                <Card.Text className='custom-card-text mb-3'>
                                    <span>
                                        {dataset.dataset_desc.match(DATA_MD_ABOUT_EXTRACT_REGEX) 
                                            ? dataset.dataset_desc.match(DATA_MD_ABOUT_EXTRACT_REGEX)[1]
                                            : dataset.dataset_desc}
                                    </span>                                
                                </Card.Text>

                                <Col xs={12} sm={12} md={3} lg={4} xl={3}>
                                    <div className="d-grid">
                                    <Button className="om-button black-gradient-hover-effect" variant="dark" onClick={() => handleViewDataset(dataset)}>
                                        <span className="ps-1 pe-1 ms-1">View Dataset </span>
                                        <FontAwesomeIcon size="1x" icon={icon({name: 'arrow-right'})} />
                                    </Button>
                                    </div>
                                </Col>
                                
                            </Card.Body>
                        </Card>
                        </animated.div>
                    )
                })                        
                    : 
                    !isLoading                         
                        ? <>
                        <div className="d-flex justify-content-center">
                        <Card className='info-display-card'>
                            <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                                <Stack>
                                    <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} />
                                    <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>{ datasets ? datasets.message : "Error on retrieving dataset"}</ReactMarkdown>
                                </Stack>
                            </Card.Body>
                        </Card>
                        </div>
                        </>
                        : <>
                        <div className="d-flex justify-content-center" style={{ marginTop : "50px"}}>
                            <Spinner animation="border" role="status" >
                            <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                        </>
                }
            		</Col>
                        </Row >
            
            
        </>
    );
}

export default ListDatasets;