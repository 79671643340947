const prod = {
    url: {
        API_URL: 'https://openmortality.org/api'
    }
};

const dev = {
    url: {
     API_URL: 'https://dev.openmortality.org/api'
    }
};

const dev_local = {
    url: {
     API_URL: 'http://localhost:3000'
    }
};

export const api_config = 
    process.env.NODE_ENV === "development" 
        ? dev_local 
        : process.env.REACT_APP_DEV_ENVIRONMENT === "staging" 
            ? dev
            : prod;