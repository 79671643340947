import React, {useState, useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/esm/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import Stack from 'react-bootstrap/Stack';

//import Navbar from 'react-bootstrap/Navbar';
//import Container from 'react-bootstrap/Container';

import { KEYWORDSEARCH_REGEX } from '../constants/regex'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import OmLogoStaticSvg from '../Assets/images/openmortality-logo.svg';

import { api_config } from "./api_url";

import './HeaderNew.css'

const HeaderNew = ({CartCount}) => {
    const api_url = api_config.url.API_URL
    
    const navigate = useNavigate();
    const location = useLocation();

    const [keyword, setKeyword] = useState("");        

    let localCart = localStorage.getItem("cart");
    let [cart, setCart] = useState([]);
    let [downloadlist, setDownloadlist] = useState([]);
    const [isLoading, setIsLoading] = useState(localStorage.getItem('isLoading'));    
    const [loggedInUser , setLoggedInUser] = useState("");    
    const [buttonText, setButtonText] = useState("");    
    const [validated, setValidated] = useState(false);
    const [validkeyword, setValidKeyword] = useState(true)

    const handleKeywordChange = (e) => {   
        setValidKeyword(true)  //clear invalid message when inputting     
        setKeyword(e.target.value)
    }

    const handleKeyDown = (e) => {        
        if (e.key === 'Enter') {
            e.preventDefault();            
            handleDatasetSearch() //datasetSearch()            
        }
    }  

    const handleDatasetSearch = async () => {        
        if (!keyword || !KEYWORDSEARCH_REGEX.test(keyword)) {                
                setValidKeyword(false)
                setValidated(false);
            }
            else
            {            
                setValidated(true);
                setValidKeyword(true)                
                navigate("/dataset?keyword=" + keyword.trim());
            }
    }

    const handleLogoClick = async () => {        
        navigate("/")        
    }

    const handleClick = (e) => {        
        switch(e.target.value){
            case "register":
                navigate("/Register");
                break;
            case "cart":
                navigate("/DownloadCart");
                break;
            default:
        }
    }

    const handleCartImageClick = (e) => {
        //navigate("/DownloadCart" );

        //console.log("View cart...", location.state ? location.state : "No state")
        navigate("/DownloadCart" , { state : 
            {
                dataset : location.state ? location.state.dataset : "",
                data : location.state ? location.state.data : "",
                user : location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))
            }            
        });
    }

    const logout = () => {        
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/user/logout", {
                method: 'POST',
                credentials: 'include',
                headers: { 
                    'Content-Type': 'application/json' 
                }
            })
            .then((res) => res.json())
            .then( async (data) => {                
                resolve(data)
                //redirect to home page
                navigate("/")            
            })
            .catch((err) => {
                reject(err)
            });
        });
    }

    const handleLoginOut = async () => {        
        if (buttonText === "Logout") {
            logout()
            .then((logoutstatus) => {
                localStorage.clear();
                setButtonText("Login");                
                setLoggedInUser("")
                navigate("/");
            })
            .catch((err) => {
                
            })
        }
        else {
            navigate("/Login");
        }
    }

    const handleGoback = () => {
        navigate(-1)
    }

    const handleViewUseInfo = () => {
        navigate("/userinfo");
    }

    const handleChangePassword = () => {
        navigate("/changepassword");
    }

    useEffect(() => {
        const strloggedInUser = localStorage.getItem("user");
        const loggedInUser = JSON.parse(strloggedInUser)
        
        setLoggedInUser(JSON.parse(strloggedInUser))
        if (loggedInUser) {
            //console.log(loggedInUser)
            setButtonText("Logout");
            //setButtonText(loggedInUser.firstname + " Logout");
        }
        else 
        {
            setButtonText("Login");
        }
    }, [])

    useEffect(() => {            
        //turn it into js
        localCart = JSON.parse(localCart);
        setCart(localCart)
        setDownloadlist(localCart)
        
        setIsLoading(localStorage.getItem('isLoading') === "true" ? true : false)

        if (localStorage.getItem('isLoading') === "true")
        {
            const checkDownloading = setInterval(() => {
                //console.log("@UAC useEffect check /2s")
                /*const newValue = localStorage.getItem('isLoading');
                if (newValue !== value) {
                    setValue(newValue);
                }*/
                if (localStorage.getItem('isLoading') === 'false')
                { 
                    clearInterval(checkDownloading);
                    setIsLoading(false)
                }
            }, 2000); // Check every 1 second    
        }

    }, [localCart])

    

  return (
    <>
        <Row className='ms-2 me-2 p-1 border-bottom border-dark border-3 shadow-sm'>
            
            <Col xs={3} sm={3} md={4} lg={4} xl={4} className='ps-md-3 pt-1 pb-1 d-flex text-start justify-content-start'>
                <img className="om-header-logo" src={OmLogoStaticSvg} onClick={() => handleLogoClick()} />            
            </Col>            
            
            
            <Form noValidate xs={6} sm={5} md={4} lg={4} xl={4} as={Col} className="d-flex align-items-center" id="FrmKeySearch" >
                <InputGroup >
                    <Form.Control
                        className='om-header-search'
                        placeholder="Search Dataset"
                        onChange={handleKeywordChange}
                        onKeyDown={handleKeyDown}
                        value={keyword}
                        isInvalid={!validkeyword}
                    />
                    
                    <Button
                        id="header-button-search-button" 
                        variant='dark'
                        className="d-flex align-items-center justify-content-center om-header-search-button red-gradient-hover-effect"
                        onClick={(e) => handleDatasetSearch()}
                    >
                        <FontAwesomeIcon className='fa-flip-horizontal om-header-search-icon' icon={icon({name: 'magnifying-glass'})} />                    
                    </Button>

                    <Form.Control.Feedback type='invalid'>                     
                        <span className="om-header-search-response-text" >Please input keyword</span>                    
                    </Form.Control.Feedback>                            
                </InputGroup>
            </Form>
            {
            !!loggedInUser 
                ? <>
                    {/* Download Cart*/}                    
                    <Col xs={3} sm={4} md={4} lg={4} xl={4} className='p-0 d-flex align-items-center justify-content-end'>                        
                        <Stack direction='horizontal' gap={1} className="p-2 p-md-1">                        
                            <div className="download-cart-item d-flex align-items-center justify-content-center" onClick={handleCartImageClick} style={{ cursor : "pointer"}}>                    
                                <FontAwesomeIcon className="pt-1 download-cart-icon" icon={icon({name: 'cart-shopping'})} />
                                {
                                    /*Purposely leave a space for Badge*/
                                    CartCount > 0 
                                    ? <span > <Badge pill bg="dark">{CartCount}</Badge></span>
                                    : isLoading
                                        ? <span className=""><Badge pill bg="dark">
                                            <FontAwesomeIcon size="1x" icon={icon({name: 'arrow-down'})} />
                                            </Badge></span>
                                        : ""
                                }
                            </div>                
                            {/* User profile */}                        
                            <Dropdown className='user-profile-dropdown'>
                                <Dropdown.Toggle className='ps-1 pe-1 pb-1 d-flex align-items-center' variant="" id="lg-dropdown-profile" >                                    
                                    <FontAwesomeIcon className="profile-icon" icon={icon({name: 'circle-user', style:'solid'})} />                                    
                                    <span className='profile-name'>                                    
                                        {loggedInUser ? loggedInUser.firstname : buttonText}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu size="sm">
                                    <Dropdown.Item size="sm" eventKey="1" onClick={(e) => handleViewUseInfo(e)} >View Profile</Dropdown.Item>
                                    <Dropdown.Item size="sm" eventKey="2" onClick={(e) => handleChangePassword(e)} >Change password</Dropdown.Item>            
                                    <Dropdown.Divider />
                                    <Dropdown.Item size="sm" eventKey="3" onClick={(e) => handleLoginOut(e)} >{buttonText}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </Stack>
                    </Col>
                </>
                : 
                <>
                    <Col xs={3} sm={4} md={4} lg={4} xl={4} className='d-flex align-items-center justify-content-end pe-3'>
                        <Button className="om-button black-gradient-hover-effect" variant="dark" id="login-button" value="register" onClick={(e) => handleLoginOut(e)} >
                            {buttonText}
                        </Button>
                    </Col>
                </>            
        }
                    
            
        </Row>
    
    </>
    
  )
}

export default HeaderNew