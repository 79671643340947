import React, { useEffect } from 'react'

import MainLogoStatic from '../components/MainLogoStatic'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/esm/Stack'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { useNavigate } from 'react-router-dom'

import TsParticles from "../components/ParticlesBackground";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const ErrorPage = () => {    

    const navigate = useNavigate();

    useEffect(() => {
        //Include Google Analytics Tag
      const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
      // Google Analytics tracking code
      const head = document.querySelector("head");
      const script1 = document.createElement("script");
      script1.async = true;
      script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
      head.appendChild(script1);
      
      const script2 = document.createElement("script");
      script2.innerText = 
        `window.dataLayer = window.dataLayer || [];\
          function gtag(){dataLayer.push(arguments);}\
          gtag('js', new Date()); \
          gtag('config', '${trackingID}');\
          gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
      head.appendChild(script2);

        // Update the document's meta tags when the component mounts
        document.title = 'Open Mortality | Page Not Found';
        document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - Page Not Found');
    
        // Clean up the meta tag modifications when the component unmounts
        return () => {
        document.title = '';
        document.querySelector('meta[name="description"]').setAttribute('content', '');
        head.removeChild(script1);
        head.removeChild(script2);
        };
    }, []);
    
    const handlebacktohome = () => {
        navigate("/")
    }

    const handlebacktoprevious = () => {
        navigate(-1)
    }
    return (
        <>
            
            
            <MainLogoStatic />
            
            <Row className='d-flex justify-content-center align-items-center mt-5 mb-3 ' >
                <Col xs={3} sm={3} md={4} lg={4} xl={3} className="d-flex justify-content-end align-items-center">
                    <FontAwesomeIcon size="3x" icon={icon({name: 'circle-exclamation'})} />
                </Col>
                <Col xs={5} sm={5} md={5} lg={5} xl={4} className="d-flex justify-content-start">
                    <h3><strong>Page not found</strong></h3>
                </Col>
            </Row>
            
            <Row className='m-2 d-flex justify-content-center align-items-center'>
                <Col xs={11} sm={11} md={10} lg={10} xl={7}>
                <p className='mt-3 mb-4 ps-2 pe-2' >Oops! It looks like the page you're trying to access couldn't be found. <br /><br />
                This may be because the URL is incorrect, the page has been removed or moved, or there may be a technical issue. Please double-check the URL. <br/><br/>                
                If you continue to experience issues, please don't hesitate to contact our support team for further assistance. We apologize for any inconvenience this may have caused.</p>
                </Col>
            </Row>

            <Row className='mb-2 d-flex justify-content-center align-items-center'>
            <Col xs={10} sm={10} md={4} lg={4} xl={2} className="m-1 ps-1 pe-1 d-flex justify-content-center justify-content-md-end">
                <Button className="om-button" variant='dark' onClick={handlebacktohome}>Home</Button>
            </Col>
                
            <Col xs={10} sm={10} md={5} lg={5} xl={3} className="m-1 ps-1 pe-1 d-flex justify-content-center justify-content-md-start">
                <Button variant='dark' className="om-button" onClick={handlebacktoprevious}>Back to previous page</Button>
            </Col>
            </Row>            
            
        </>
    )
}

export default ErrorPage