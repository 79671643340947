import React from 'react'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Stack, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import "./Footer.css"

const Footer = () => {
    const version = "v1.0.0"
    return (
      <>      
        <Row className="mb-3 d-flex justify-content-center align-items-center">
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-1">
              {/* Copyright */}
              <span className="footer-text">
                © 2022-{new Date().getFullYear()} Centre for Global Health Research, Unity Health Toronto, and University of Toronto
              </span>
          </Col>
            
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex align-items-center justify-content-center mb-1">
              {/* Contact */}            
              <Button className="footer-icon p-0 me-3" variant='link' href='https://www.instagram.com/open_mortality' target="_blank" > 
                <FontAwesomeIcon size="lg" icon={icon({name: 'instagram', style:"brands"})} style={{ color: "grey" }}/> 
              </Button>

              <Button className="footer-icon p-0 me-3" variant='link' href='https://twitter.com/open_mortality' target="_blank" > 
                  <FontAwesomeIcon size="lg" icon={icon({name: 'x-twitter', style: "brands" })} style={{ color: "grey" }}/> 
              </Button>

              <Button className="footer-icon p-0" variant='link' href='mailto:contact@openmortality.org' target="_blank" >
                <span><FontAwesomeIcon className='me-2' size="lg" icon={icon( {name : "envelope", style:'regular'} )} style={{ color: "grey" }} />
                  contact@openmortality.org
                </span>
              </Button>
            </Col>
            
            {/* Version */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
            <span className="footer-text" >
                {                  
                  process.env.NODE_ENV === "development" 
                    ? <span style={{ backgroundColor : "cyan", color: "grey"}}> {version} (Local Development) </span>
                    : process.env.REACT_APP_DEV_ENVIRONMENT === "staging"
                      ? <span style={{ color: "grey"}}>{version} (Development) </span>
                      : <span>{version}</span>
                }
            </span>
          </Col>
        </Row>
      </>
    )
}

export default Footer