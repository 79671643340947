import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

//import { useNavigate } from 'react-router-dom';

import './MainLogoAnimated.css';

//import {ReactComponent as OmLogo} from '../Assets/images/openmortality-logo.svg';
//import OmLogoAnimated from '../Assets/images/OM_Logo_Animated.gif';
import OmLogoAnimated from '../Assets/images/OM_Logo_Cropped_Animated.gif';

const Header = () => {
    //const navigate = useNavigate();
    
    return (
        <>
            <Row className="d-flex justify-content-center align-items-center m-0 p-0 fade-in">
                <Col xs={8} sm={6} md={5} lg={5} xl={5} className="d-flex justify-content-center m-0 p-0 fade-in">
                    <img alt="OMP Logo" className="animated-logo-main img-fluid" src={OmLogoAnimated} />
                </Col>
            </Row>            
        </>
    )
}

export default Header;
