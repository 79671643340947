import React, { useEffect } from 'react'
import {Row, Col, Container, Button} from 'react-bootstrap'
import { Navigate, useNavigate } from 'react-router-dom';

import MainLogoStatic from '../components/MainLogoStatic'
import TsParticles from "../components/ParticlesBackground";

import { useSpring, animated } from '@react-spring/web';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import "./About.css"

const About = () => {    

    const navigate = useNavigate();
    
    const handleGoBack = () => {
        navigate('/')
    }
    
    //Set metadata
    useEffect(() => {
        //Include Google Analytics Tag
      const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
      // Google Analytics tracking code
      const head = document.querySelector("head");
      const script1 = document.createElement("script");
      script1.async = true;
      script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
      head.appendChild(script1);      
      const script2 = document.createElement("script");
      script2.innerText = 
        `window.dataLayer = window.dataLayer || [];\
          function gtag(){dataLayer.push(arguments);}\
          gtag('js', new Date()); \
          gtag('config', '${trackingID}');\
          gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
      head.appendChild(script2);

        // Update the document's meta tags when the component mounts
        document.title = 'Open Mortality | About';
        document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - About');

        // Clean up the meta tag modifications when the component unmounts
        return () => {
        document.title = '';
        document.querySelector('meta[name="description"]').setAttribute('content', '');
        head.removeChild(script1);
        head.removeChild(script2);
        };
    }, []);

    const aboutText = 
        `Our mission is to empower science for improving global health with the world's information on mortality - helping the living study the dead.\n\n\### Closing the Global Mortality Divide\n\n\The causes for a majority of deaths around the world are unknown. These deaths occur at home without medical attention. We collect and release data on deaths outside medical settings to improve measures of mortality - providing the foundation for large-scale evidence-based decisions and policies.\n\n\### Building Healthier Futures\n\n\Death in old age is inevitable - death before old age is not. Premature deaths worldwide are preventable with lifestyle changes, treatment, and better access to healthcare. The Open Mortality project provides universal and accessible digital tools that enable doctors, scientists, researchers, and even the public, to uncover innovative interventions for reducing premature deaths and improving the quality of life for everyone.\n\n\### Making it Happen\n\n\The Open Mortality project is led by the Centre for Global Health Research (CGHR) at Unity Health Toronto and the University of Toronto. It is currently released as a web prototype to a select community of users for pilot testing. We have plans to release Open Mortality to academic and research institutions, collaborators, partners, and eventually, the world.\n\n`
    
    const props = useSpring({
        from: { x : -50, y : 0, opacity: 0.1 },
        to: { x : 0, y: 0, opacity : 1 },    
        config: {
            mass: 5,
            friction: 70,
            tension: 270,
            precision: 0.0001,   
            delay : 1       
        },
    })

    return (

        <>
            <TsParticles />

            <Container className='ps-5 pe-5' style={{ height : "100%", width : "100%", zIndex:1}} >
            
            <Row className="m-2 d-flex justify-content-center">
                <Col style={{ zIndex : 1}}>
                    <MainLogoStatic />
                </Col>
            </Row>
            
            <Row className="m-2 d-flex justify-content-center">
                <Col xs={12} sm={12} md={10} lg={8} xl={8} className="d-flex justify-content-center" style={{ zIndex : 1}} >
                    <h2>About</h2>
                </Col>
            </Row>
            
            <Row className='d-flex justify-content-center m-auto mb-2'>
                <Col xs={12} sm={12} md={10} lg={8} xl={8} className="" style={{ zIndex : 1}}>
                <animated.div style={{...props}}>                    
                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={aboutText} />
                </animated.div>
                </Col>
            </Row>

            <Row className='d-flex align-items-center justify-content-center'>
                <Col xs={11} sm={11} md={12} lg={12} xl={12} className="d-flex justify-content-center" style={{ zIndex : 1}}>
                    <Button variant="secondary" className='om-button' onClick={ () => handleGoBack()}>Back</Button>
                </Col>
            </Row>
            </Container>            
            
        </>
    );

}

export default About