
import { Buffer } from "buffer";
import React, { useState, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Button, Col, Row, Form } from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';

import { QueryBuilder, formatQuery, ActionElement, ActionWithRulesAndAddersProps, } from 'react-querybuilder';

import { QueryBuilderBootstrap, BootstrapControlElement } from '@react-querybuilder/bootstrap';


import 'react-querybuilder/dist/query-builder.scss';

import './NonSpatialQueryBuilder.css'

import {
  bootstrapControlClassnames,
  bootstrapControlElements,
} from '@react-querybuilder/bootstrap';

import 'bootstrap-icons/font/bootstrap-icons.scss';
import 'bootstrap/scss/bootstrap.scss';

const useMemoizedQueryParams = () => {
  const location = useLocation();  
  // This will only be recalculated when the location object changes (query params change)
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);  
  return queryParams;
};

const NonSpatialQueryBuilder = ({dataDict, table, setQuery, query, queryString, setIsCaseSensitive, isCaseSensitive, totalRecordCount}) => {
  const queryParams = useMemoizedQueryParams(); 

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  
  const [isValidQuery, setIsValidQuery] = useState(true);

    
  const validateRules = (rulesTobeValidate) => {    
    let isValid = true;
    if (Array.isArray(rulesTobeValidate.rules) && rulesTobeValidate.rules.length > 0 ) {
      rulesTobeValidate.rules.forEach( rule => {        
        if (Array.isArray(rule.rules) ) {
          const isValidFromRecursive = validateRules(rule)
          isValid = isValid && isValidFromRecursive;
        }
        else {
          if ( 
            ( rule.operator === '~') ||
            ( (!(rule.operator.toUpperCase() === 'NULL' || rule.operator.toUpperCase() === 'NOTNULL')) && (rule.value === null || rule.value === "") ) ||
            ( (rule.operator.toUpperCase() === 'BETWEEN' || rule.operator.toUpperCase() === 'NOTBETWEEN') && (rule.value.split(',')[0].trim() === "" || rule.value.split(',')[1] === "") ) ||
            ( (rule.operator.toUpperCase() === 'BETWEEN' || rule.operator.toUpperCase() === 'NOTBETWEEN') && ( parseFloat(rule.value.split(',')[0]) >= parseFloat(rule.value.split(',')[1])) )
          ) 
          { 
            isValid = false            
          }
        }
      })
    }
    else
    {
      isValid = false
    }
    return isValid;
  }       
  
  const parseQuery = () => {
    const modQueryObject = { ...formatQuery(query, { format : 'parameterized' }) }
    
    if (!isCaseSensitive)
    {
      //Extract all the conditions to an array in the query i.e. conditions
      const regex = /\w+\s(=|!=|>|<|>=|<=|like|not\slike|between\s\?\sand)\s\?|(\w+\s(?:in|not in)\s+\([^\)]+\))|\w+\s(is(\s|\snot\s)null)/g;
      const conditions = modQueryObject.sql.match(regex);
                
      let hasBetweenCondition = 0;
      let hasInTypeCondition = 0;
      let hasNullTypeCondition = 0;
      let newSql = modQueryObject.sql
      
      //Loop through all the conditions
      conditions.map( (c, conditionIndex) => {
        //Exclude `between` as the value should be numeric type
        if (!c.includes("between") && !c.includes("null") )
        {          
          //Offset the rule index with value index as some of the condition having more than 1 value
          const valueIndex = conditionIndex + hasBetweenCondition + hasInTypeCondition + hasNullTypeCondition

          //split individual condition to 3 parts:             
          const regex = /\s(=|!=|>|<|>=|<=|like|not\slike|in|not\sin)\s/g;

          //condition[0] = field name
          //condition[1] = operator
          //condition[2] = value
          const condition = c.split(regex).map(condition => condition.trim());          
                    
          //If the value of the where condition is string OR the operator is `in` or `not in`
          if (isNaN(parseFloat(modQueryObject.params[valueIndex])) || condition[1] === 'in' || condition[1] === 'not in')
          { 
            //Special handle for `in` type condition as there is more than 1 value
            if (condition[1] === 'in' || condition[1] === 'not in')
            {              
              hasInTypeCondition = -1;
              //Convert all the value in value separated by comma to uppercase
              condition[2].split(',').map(()=> {                
                hasInTypeCondition = hasInTypeCondition + 1
                modQueryObject.params[valueIndex + hasInTypeCondition] = modQueryObject.params[valueIndex + hasInTypeCondition].toUpperCase()                
              }) 
            }
            //Add `UPPER()` to the filed and replace it in query
            const newCondition = c.replace(condition[0], `UPPER(${condition[0]})`)
            newSql = newSql.replace( c.trim() , newCondition )
            
            //Convert the value in params to upper case
            modQueryObject.params[valueIndex] = modQueryObject.params[valueIndex].toUpperCase()
          }
        }
        else if (c.includes("between"))
        {
          //As there are 2 values for between condition, so, add 1 to the index for getting parameter (value)
          hasBetweenCondition = hasBetweenCondition + 1
        }
        else if (c.includes("null"))
        {
          //As there is no parameter in nul type condition, so, minus 1 to the index for getting parameter (value)
          hasNullTypeCondition = hasNullTypeCondition - 1
        }
        else return
      })
      modQueryObject.sql = newSql
    }    
    return modQueryObject
  }

   // Handle the toggle switch change event
   const handleToggleChange = (e) => {    
    setIsCaseSensitive(e.target.checked);
  };


  const submitQuery = () => {
    //Clear the state before checking
    setIsValidQuery(null)    
    const valid = validateRules(query);    
    setIsValidQuery(valid) 

    if (valid)
    { 
      const finalParameterizedQuery = parseQuery()      
      let base64query = Buffer.from(JSON.stringify( { ...finalParameterizedQuery, originalSQL: formatQuery(query, { format : 'parameterized' }), isCaseSensitive: isCaseSensitive } )).toString('base64')

      //Make it URL safa
      base64query = base64query.replaceAll("+", "-").replaceAll("/","_").replaceAll("=","")
      
      navigate("/data/" + (params.data_name)  +"?pageSize=10&climit=5&page=1&query=" + base64query , {
        state: {        
          data: location.state ? location.state.data : "",
          dataset: location.state ? location.state.dataset : "",
          user : location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))          
        },
      })
    }
  }

  const initialQuery = { combinator: 'and', rules: [] };

  const clearQuery = () => {
    setIsValidQuery(true)
    setIsCaseSensitive(false)
    setQuery(initialQuery)
    navigate("/data/" + params.data_name  +"?pageSize=10&climit=5&page=1", {
      state: {        
        data: location.state ? location.state.data : "",
        dataset: location.state ? location.state.dataset : "",
        user : location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))                
      },
    })

  }

  const queryValidator = (q) => {return q.rules.length > 0};

  const ruleValidator = (r) => {
    //console.log("rule validator...", r, !!r.value)    
    return {valid : !!r.value}
  };  

  const fields = Object.entries(dataDict.data).map( e => 
    (
      {
        name: e[1].column, 
        label: e[1].column + " [" + e[1].type + "] " + e[1].description, 
        inputType : e[1].type === 'integer' || e[1].type === "bigint" || e[1].type === "double precision"
                    ? "number"
                    : e[1].type === 'timestamp without time zone'
                      ? "datetime-local"
                      : "text",
        valueSource : '',
        placeholder : e[1].column + " [" + e[1].type + "]",
        operators: e[1].type === 'integer' || e[1].type === "bigint" || e[1].type === "double precision" || e[1].type === 'timestamp without time zone'
          ? [
              { name: '=', label: '=' },
              { name: '!=', label: '!=' },
              { name: '<', label: '<' },
              { name: '>', label: '>' },
              { name: '<=', label: '<=' },
              { name: '>=', label: '>=' },
              { name: 'null', label: 'is null' },
              { name: 'notNull', label: 'is not null' },
              { name: 'between', label: 'between' },
              { name: 'notBetween', label: 'not between' },
            ] 
          : [
              { name: '=', label: '=' },
              { name: '!=', label: '!=' },          
              { name: 'contains', label: 'contains' },              
              { name: 'doesNotContain', label: 'does not contain' },              
              { name: 'null', label: 'is null' },
              { name: 'notNull', label: 'is not null' },
              { name: 'in', label: 'in' },
              { name: 'notIn', label: 'not in' }          
            ],
          separator:",",
          validator: ruleValidator
      }
    )
  )

  const CustomRuleGroup = (actionWithRulesAndAddersProps) => {    
    return (
      <>      
      {
      //Display the case sensitive control at top level only as it only applies to query globally
      actionWithRulesAndAddersProps.level === 0 
        ? <Form.Check
          className="mt-1 case-sensitive-toggle"
          type="switch"
          label="Case Sensitive"
          id=":r1u:"
          onChange={handleToggleChange}      
          checked={isCaseSensitive}
          size="sm"
        />
        :""
      }        
      <ActionElement {...actionWithRulesAndAddersProps} className="qb-add-rule-button" />        
      </>
    );
  };

  /*const CustomNotToggle = (notToggleProps) => {
    return (
      <>        
        <ActionElement {...notToggleProps} className="qb-custom-not-toggle" />        
      </>
    );
  }*/
  
  const handleQueryChange = (q) => {    
    setIsValidQuery(true)
    setQuery(q)
  }  
      
  return (    
    <QueryBuilderBootstrap>      
      <QueryBuilder
        showNotToggle  
        fields={fields}
        query={query}
        validator={validateRules}
        onQueryChange={q => handleQueryChange(q)}
        autoSelectField={false}
        autoSelectOperator={false}        
        controlClassnames={{ 
          ...bootstrapControlClassnames,
          queryBuilder: 'queryBuilder-branches', 
          addRule: 'bold',
        }}
        controlElements={{
          ...bootstrapControlElements,
          addGroupAction: props => (props.level <= 1 ? <ActionElement {...props} className="qb-add-group-button"/> : null),
          addRuleAction : props => CustomRuleGroup(props),
          removeRuleAction: props => (<ActionElement {...props} className="qb-remove-rule-button"/>),
          removeGroupAction: props => (<ActionElement {...props} className="qb-remove-rule-button"/>)
        }}        
      />
    
    <Row className='mt-2'>
      <Col className="mt-2 d-flex justify-content-lg-end" xs={12} sm={12} md={6} lg={6} xl={6} >
        <Button className='om-button' variant='dark' size='sm' onClick={submitQuery}>Apply</Button>
      </Col>
      <Col className='mt-2 d-flex justify-content-lg-start' xs={12} sm={12} md={6} lg={6} xl={6}>
        <Button className="qb-clear-button" variant="outline-secondary" size='sm' onClick={clearQuery}>Clear</Button>
      </Col>
    </Row>
    
    
    <Row className='mt-4 '>
      <Col>
        <span style={{ color : "red"}}>{isValidQuery ? "" : "Invalid Query"}</span>        
      </Col>
    </Row>    
    
    </QueryBuilderBootstrap>
  
  )
}

export default NonSpatialQueryBuilder