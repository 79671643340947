import React from 'react'
import Particles from "react-tsparticles";

import { loadFull } from "tsparticles";

const ParticlesBackground = () => {
  
    const particlesInit = async (main) => {
        await loadFull(main);
      };        
    
      const particlesLoaded = container => {
        //console.log(container);
      };
  
  return (
    <>
        <div style={{ position: "absolute", top: 1, left: 1, width: "1%", height: "1%", zIndex: 0 }}>        
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                preset: "links",
                background: {
                    color: {
                        value: "#ffffff",
                    },
                },
                fpsLimit: 25,
                particles: {
                    color: {
                        value: "#e1e1e1",
                    },
                    links: {
                        color: "#e1e1e1",
                        distance: 180,
                        enable: true,
                        opacity: 0.5,
                        width: 2,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 0.6,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 50,
                    },
                    opacity: {
                        value: 1,
                    },
                    shape: {
                        type: "square",
                    },
                    size: {
                        value: { min: 1, max: 2 },
                    },
                },
                detectRetina: true,
            }}
        /></div>
    </>

    
  )
}

export default ParticlesBackground