import React, { useState, useEffect, useMemo } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { useParams, useLocation, useNavigate, json } from 'react-router-dom';
import { Buffer } from "buffer";

import { api_config } from "./api_url";

import { Table, Form, Button, Col, Row, Card, Modal, Badge, Stack} from 'react-bootstrap';

import { parseSQL } from 'react-querybuilder';

//import Badge from 'react-bootstrap/Badge';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';

import NonSpatialQueryBuilder from './NonSpatialQueryBuilder';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import './react_table.css'
import './DisplayDataPreview.css'

const DisplayPreview = ({jsonData, dataDict, query, setQuery, queryString, setIsCaseSensitive, isCaseSensitive}) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
       
    //const [data, setData] = useState([]);
    const [ColumnName, setColumnName] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    //const [offset, setOffset] = useState(0);
    const [dataPage, setdataPage] = useState();
    const [limit, setLimit] = useState(10);
    const [columnLimit, setColumnLimit] = useState(5);
    const [hasMore, sethasMore] = useState();

    const [showDataDetail, setShowDataDetail] = useState(false);
    const [showQueryBuilder, setShowQueryBuilder] = useState(false);
    
    const [detail, setDetail] = useState([]);

    const loggedInUser = localStorage.getItem("user");
   
    const [filterInputWithField, setFilterInputWithField] = useState({});
    const [filteredData, setFilteredData] = useState([]);
    const [filterExactMatch, setfilterExactMatch] = useState(false)
    
    const handleClose = () => setShowDataDetail(false);
    const handleShow = () => setShowDataDetail(true);

    const handleCloseQueryBuilder = () => setShowQueryBuilder(false);
    const handleShowQueryBuilder = () => setShowQueryBuilder(true);
        
    const useMemoizedQueryParams = () => {
        const location = useLocation();  
        // This will only be recalculated when the location object changes (query params change)
        const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);  
        return queryParams;
    };
    
    const queryParams = useMemoizedQueryParams();

    useEffect(() => {        
        try {
            if ((!!queryString) && (query.rules.length === 0))
            {                
                const queryFromQueryString = JSON.parse(Buffer.from(decodeURIComponent(queryString), 'base64').toString('utf-8'))
                const originalSQL = queryFromQueryString.originalSQL
                const isCaseSensitiveFromQS = queryFromQueryString.isCaseSensitive
                setIsCaseSensitive(isCaseSensitiveFromQS)      
                setQuery(parseSQL(originalSQL.sql, { params : originalSQL.params }))
            }            
        }
        catch (err) {
            console.log(err)
        }        
    },[])

    useEffect(() => {
        if (jsonData.data.length > 0)
        {            
            const filteredData = jsonData.data.filter((row) =>
                Object.entries(filterInputWithField).every(([column, value]) => {                    
                    if (filterExactMatch)
                    {
                        const regex = new RegExp(`^${value}$`, 'i'); // for exact match
                        return !value || regex.test(row[column]?.toString());
                    }
                    else
                    {
                        const partialRegex = new RegExp(`${value}`, 'i'); // for partial match
                        return !value || partialRegex.test(row[column]?.toString());
                    }
                })
            );
            setFilteredData(filteredData);        
        }
      }, [jsonData, filterInputWithField, filterExactMatch]);
      
    const handleColSearch = (column) => (e) => {
        setFilterInputWithField((prev) => (
            { ...prev, 
                [column]: e.target.value 
            })
        ); 
    }
    
    const api_url = api_config.url.API_URL
    let columns = "";
    
    const refreshToken = () => {        
        var cur_user = JSON.parse(loggedInUser);
        return new Promise(async (resolve, reject) => {
            //const accessToken = loggedInUser ? JSON.parse(loggedInUser).token : "";
            fetch(api_url + "/user/refresh", {
                credentials: 'include',
                headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'                        
                }
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                //console.log(myJson)
                if (myJson.refresh)
                {
                cur_user['token'] = myJson.token;
                localStorage.setItem('user', JSON.stringify(cur_user));                      
                }
                resolve(myJson.token)
            });
        })    
    }

    const viewFullRecordbyId = async (table_name, id_field, id) => {
        const accessToken = await refreshToken()
        setIsLoading(true)
        return new Promise(async (resolve, reject) => {            
            fetch(api_url + "/data/" + table_name + "/" + id_field + "/" + id , {            
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    Authorization: "Bearer " + accessToken
                }
            })
            .then(function (response) {
                return response.json();                
            })
            .then(function (myJson) {                                    
                resolve(myJson)                
                setIsLoading(false)                               
            })
            .catch( (err) => {
                setIsLoading(false)
                reject(err)                
            });
        })
    }

    useEffect(() => {        
        const page = parseInt(queryParams.get('page')) > 0 ? queryParams.get('page') : 1;
        const pageSize = parseInt(queryParams.get('pageSize')) > 0 ? queryParams.get('pageSize') : 10;
        const climit = parseInt(queryParams.get('climit')) > 0 ? queryParams.get('climit') : 5;
        
        setPageSize(pageSize)        
                
        //Get the first record to create table header; and take first 10 columns for previewing
        if (Object.keys(jsonData).includes("data") && jsonData.data.length > 0)
        {   
            const cols = Object.keys(jsonData.data[0])                
                .map( (key) => ({                
                    Header: key,
                    accessor: key
                }));                        
            sethasMore(jsonData.hasMore)
            setColumnName(cols);
            setIsLoading(false)
        }
        
    }, [jsonData])

    const handleLoadMore = async () => {
        const page = parseInt(queryParams.get('page')) > 0 ? queryParams.get('page') : 1;
        const pageSize = parseInt(queryParams.get('pageSize')) > 0 ? queryParams.get('pageSize') : 10;
        const climit = parseInt(queryParams.get('climit')) > 0 ? queryParams.get('climit') : 5;

        
        const q = !!queryString ? "&query=" + queryString : ""

        navigate("/data/" + params.data_name +"?pageSize=" + pageSize + "&climit=" + climit + "&page="+ (parseInt(page) + 1) + q, {            
            state: {        
              data: location.state ? location.state.data : "",
              dataset: location.state ? location.state.dataset : "",
              user : location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))                
            },
          } );
    }

    const handleLoadPrevious = async () => {
        const page = parseInt(queryParams.get('page')) > 0 ? queryParams.get('page') : 1;
        const pageSize = parseInt(queryParams.get('pageSize')) > 0 ? queryParams.get('pageSize') : 10;
        const climit = parseInt(queryParams.get('climit')) > 0 ? queryParams.get('climit') : 5;
        
        const q = !!queryString ? "&query=" + queryString : ""

        navigate("/data/" + params.data_name  +"?pageSize=" + pageSize + "&climit=" + climit+ "&page="+ (parseInt(page) - 1) + q, {            
            state: {        
              data: location.state ? location.state.data : "",
              dataset: location.state ? location.state.dataset : "",
              user : location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))                
            },
          })        
    }

    const viewFullRecord = async(row) => {        
        const id_field = "_id" //use `_id` as the id field which dedicated to OMP        
        const fullRecordResult = await viewFullRecordbyId(params.data_name, id_field, row.values[id_field] )
        if (fullRecordResult.success)
        {            
            setDetail(fullRecordResult.data)
        }
        else            
            setDetail(fullRecordResult)
        
        handleShow(true)
    }

    columns = React.useMemo(() => ColumnName, [ColumnName])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        setPageSize, //Pagination
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex, pageSize },
    } = useTable(
        { 
            columns, 
            data: filteredData,
            initialState: { pageIndex: 0, pageSize: limit }
        }, 
        useFilters, useSortBy, usePagination
    );

    return (
        
        Object.keys(jsonData).includes("data")
            ? <>
                <Modal size="lg" centered show={showDataDetail} onHide={handleClose} className='view-data-detail-pop-up'>
                <Modal.Header closeButton>
                    <span style={{ fontWeight : "bold"}}>
                        {`Record No.: ${detail['_id']}`}
                    </span>
                </Modal.Header>
                
                <Modal.Body>
                {
                    !Object.keys(detail).includes("message") || detail.success //Check if session expired or error
                    ? <div className="full-table-record-viewing-container" >
                        <Table className="full-table-record-viewing">                        
                        <thead>
                            <tr>
                                <th>Column</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {                           
                                Object.keys(detail).map( (d, key) => (                                
                                    <tr key={key} >
                                        <td >
                                            <span>{d}&nbsp;</span>
                                            <OverlayTrigger placement='auto'
                                                overlay={
                                                    <Tooltip id="tooltip-disabled">
                                                        <Stack className='d-flex align-items-center' direction='vertical'>
                                                            <code className='p-1' style={{color : "white"}}>
                                                                <span>{dataDict.data[dataDict.data.findIndex(dd_entry => dd_entry.column === d)].description}</span>
                                                            </code>
                                                            <Badge pill size="xs" bg="secondary">{dataDict.data[dataDict.data.findIndex(dd_entry => dd_entry.column === d)].type}</Badge>
                                                        </Stack>
                                                    </Tooltip>
                                                }>
                                                <FontAwesomeIcon className='' size="lg" icon={icon({name: 'circle-question', style:'regular'}) } style={{ color: "#5a5a5a" }} />
                                            </OverlayTrigger>
                                        </td>
                                        <td>{detail[d]}</td>
                                    </tr>
                                ))                     
                            }
                        </tbody>
                        
                        </Table>
                        </div>
                    :<><div className='d-flex justify-content-center m-2'><span>{detail.message}</span></div></>
                }                    
                </Modal.Body>
                
                {/*<Modal.Footer className='d-flex justify-content-center'>
                    <Button className='om-button black-gradient-hover-effect' variant="dark" onClick={handleClose}>Close</Button>
                </Modal.Footer>*/}
                </Modal>

                <Modal size="xl" centered show={showQueryBuilder} onHide={handleCloseQueryBuilder} className='query-builder-pop-up'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Query Builder
                    </Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <NonSpatialQueryBuilder
                        totalRecordCount={Object.keys(jsonData).includes("totalRecordCount") ? jsonData.totalRecordCount : null}
                        dataDict={dataDict} 
                        table={params.data_name} 
                        setQuery={setQuery} 
                        query={query} 
                        queryString={queryString} 
                        setIsCaseSensitive={setIsCaseSensitive}
                        isCaseSensitive={isCaseSensitive}
                    />
                </Modal.Body>
                
                </Modal>

                {/* page navigation */}
                <Row className='ms-1 me-1 mb-1 mt-3'>
                <Col xs={6} sm={6} md={4} lg={4} xl={4} className="p-1 d-flex align-items-center justify-content-start">
                    {
                    queryParams.get('page') > 1 
                    ? 
                    <>
                        <span className="me-1 d-flex align-items-center justify-content-center icon-button" onClick={handleLoadPrevious} >
                            <span className="me-1">
                                <FontAwesomeIcon className='icon-container' size="lg" icon={icon({name: 'angle-left'})} />
                            </span>
                            <span className='me-1 button-text'>Previous</span>
                        </span>
                    </>                        
                    :
                    <>  <span className="me-1 d-flex align-items-center justify-content-sm-start justify-content-center icon-button">
                            <span className='me-1'>
                                <FontAwesomeIcon size="lg" icon={icon( {name : "angle-left"} )} style={{ color: "grey" }} />                            
                            </span>
                            <span className='me-1 text-secondary'>Previous</span>
                        </span>
                    </>
                    }

                    {
                        hasMore
                        ? <>
                            <span className="ms-1 d-flex align-items-center justify-content-center icon-button" onClick={handleLoadMore} >
                                <span className='ms-1 button-text'>Next</span>
                                <span className="ms-1">
                                    <FontAwesomeIcon className='icon-container' size="lg" icon={icon({name: 'angle-right'})}  />
                                </span>
                            </span>
                        </>
                        : <>
                            <span className='ms-1 d-flex align-items-center justify-content-center icon-button'>
                                <span className='ms-1 text-secondary'>Next</span>
                                <span className="ms-1">
                                    <FontAwesomeIcon size="lg" icon={icon( {name : "angle-right"} )} style={{ color: "grey" }}  />
                                </span>
                            </span>
                        </>
                    }
                </Col>
                
                {/* row count */}
                <Col xs={6} sm={6} md={5} lg={5} xl={5} className="d-flex align-items-center justify-content-end">
                    <span className='totalRecordCount'>{ 
                        !!queryString && Object.keys(jsonData).includes("totalRecordCount") 
                            ? `${jsonData.recordCount.toLocaleString('en-US')}/` + `${jsonData.totalRecordCount.toLocaleString('en-US')} rows`  
                            : `${jsonData.recordCount.toLocaleString('en-US')} rows`}
                    </span>
                </Col>

                {/* Query Builder button */}
                <Col xs={12} sm={12} md={3} lg={3} xl={3} className="d-flex align-items-center justify-content-sm-end justify-content-center">
                    {
                    query.rules.length > 0
                        ? <Button variant='dark' className='query-button black-gradient-hover-effect d-flex justify-content-center align-items-center' onClick={handleShowQueryBuilder}>                                    
                            <span><FontAwesomeIcon id="query-in-force" className="" size="sm"  icon={icon( {name : "pen-to-square"} )} style={{ color: "white" }} />&nbsp;Edit Query</span>                                    
                        </Button>
                        : <Button variant='dark' className='query-button black-gradient-hover-effect d-flex justify-content-center align-items-center' onClick={handleShowQueryBuilder}>
                            <span><FontAwesomeIcon id="no-query-in-force" className="" size="sm"  icon={icon( {name : "pen-to-square"} )} style={{ color: "white" }} />&nbsp;Query</span>
                        </Button>
                    }
                </Col>
                </Row>
                
                <div className="ms-2 me-2">
                <Table borderless className="table-data-viewing" responsive="sm" {...getTableProps()} >
                    <thead>                        
                        {headerGroups.map((headerGroup) => (                            
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {                                    
                                    headerGroup.headers
                                    .map((column) => (                                        
                                        <th {...column.getHeaderProps()} >
                                            {column.render('Header')}                                            
                                            {/*<Form.Control
                                                className='table-data-viewing-textbox'                                                
                                                type="text"
                                                placeholder="Filter"
                                                name={column.render('Header')}                                                
                                                value = {filterInputWithField[column.render('Header')] || ""}                            
                                                onChange={handleColSearch(column.render('Header'))}
                                                pattern="/[\w\s./-]/g/"
                                            />*/}
                                        </th>
                                    ))
                                }
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()} >
                    {                            
                        page.map(row => {
                            prepareRow(row)
                            return (
                                <>                                        
                                    <tr {...row.getRowProps()} >
                                    {
                                        row.cells                                            
                                        .map((cell, index) => {   
                                            return index === 0 //Add 'view' button for first cell
                                                ? <td key={index} {...cell.getCellProps()} ><span>{cell.render('Cell')}</span><br />
                                                    <Button size="sm" variant="dark" className='view-full-record-button black-gradient-hover-effect' onClick={e=>viewFullRecord(row)}>
                                                        <span className='me-2'>View</span><FontAwesomeIcon className="mt-1" size="1x" icon={icon({name: 'arrow-right'})} />
                                                    </Button>
                                                  </td>
                                                : <td key={index} {...cell.getCellProps()} >{ cell.render('Cell') }</td>
                                                })
                                            }
                                    </tr>                                        
                                </>
                            )
                        })
                    }
                    </tbody>
                    </Table>
                </div>                    
            </>        
        :   
            Object.keys(jsonData).includes("token") || Object.keys(jsonData).includes("permission")
            ?
            <>            
            <Row className='m-1 align-content-center justify-content-center text-center' >                  
                <Card style={{ width: '58rem', marginTop: "100px" }}>
                    <Card.Body>
                    <Card.Text>
                        <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} /><br /><br />
                        <span className=''> {jsonData.message}</span><br /><br />
                        <Button className='om-button' variant='dark' href='/login' style={{ textDecoration : "none"}}>Login</Button>
                    </Card.Text>
                </Card.Body>
                </Card>                  
            </Row>
            </>
            : 
                isLoading
                    ?<>
                    <div className="d-flex justify-content-center" style={{ marginTop : "50px"}}>
                        <Spinner animation="border" role="status" >
                        <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                    </>
                    :<></>        

    )
        
    
    /*
    if (auth) {
        return (
            <>                
                <Table striped bordered responsive="sm" {...getTableProps()} >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()} style={{ textAlign: "left" }}>{column.render('Header')}</th>
                                    ))
                                }
                            </tr>
                        ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                        {
                            rows.map(row => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} style={{ textAlign: "left" }}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </>
        )
    }
    else
        return (
            <div><h3>{data.message}</h3></div>
        )*/
}

export default DisplayPreview