import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import './OmpSlogan.css';

const SubHeader = () => {
    return (
        <>
          <Row className='d-flex fade-in'> 
                <Col className="text-center">          
                  <h4 className="p-2 m-0 slogan-font slogan">
                    <span className="slogan-color1">Help the living</span>
                    <span className="slogan-color2"> Study the dead</span>
                  </h4>
                </Col>
          </Row>
        </>
    )
}

export default SubHeader;