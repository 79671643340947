import React, { useState, useRef, useEffect} from "react";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import MainLogoStatic from '../components/MainLogoStatic'

import { api_config } from "../components/api_url";

import { useNavigate } from "react-router-dom";

import TsParticles from "../components/ParticlesBackground";

const ForgotPassword = () => {
  const api_url = api_config.url.API_URL
  const [message, setMessage] = useState("");
  //const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  
  const emailInputElement = useRef();

  useEffect(() => {
    //Include Google Analytics Tag
    const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
    // Google Analytics tracking code
    const head = document.querySelector("head");
    const script1 = document.createElement("script");
    script1.async = true;
    script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
    head.appendChild(script1);
    
    const script2 = document.createElement("script");
    script2.innerText = 
      `window.dataLayer = window.dataLayer || [];\
        function gtag(){dataLayer.push(arguments);}\
        gtag('js', new Date()); \
        gtag('config', '${trackingID}');\
        gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
    head.appendChild(script2);
    // Update the document's meta tags when the component mounts
    document.title = 'Open Mortality | Forgot Password';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality  - Forgot Password');

    // Clean up the meta tag modifications when the component unmounts
    return () => {
    document.title = '';
    document.querySelector('meta[name="description"]').setAttribute('content', '');
    head.removeChild(script1);
        head.removeChild(script2);
    };
  }, []);

  const handleBack = () => {
    navigate(-1)
  }

  const handlePasswordReset = () => {
  return new Promise( (resolve, reject) => {
    try{
      fetch(api_url+"/user/accountHelp" ,{ 
          method: 'POST',
          credentials: 'include',
          headers: { 
            'Content-Type': 'application/json',           
          },
          body: JSON.stringify({
            //email: form.email
            email : emailInputElement.current?.value,
          }),
      })
      .then((res) => res.json())
      .then((data) => {
          resolve(data)
          setMessage(data.message)
          setValidated(false)
      })
      .catch((err) => {                    
          //setSessionUser("No session")
          reject(err)
      });    
    }
    catch(err) {                
        reject(err)
    }
  })
}

const clearError = () => {
  setMessage("")
}

  return (
    <>
      <TsParticles />
      
      <div className="d-flex justify-content-center align-items-center" style={{ height : "80vh"}}>
      
      <Form as={Row} className="justify-content-center" noValidate validated={validated} style={{ zIndex : 1}}>
          
          <MainLogoStatic />
          
          <Form.Group as={Row} className="d-flex justify-content-center p-1" controlId="formForgotPasswordInstruction">              
            <Form.Label column xs={10} sm={10} md={9} lg={8} xl={6} className="text-center">
              Enter the email address associated with your account and We'll send you a link to reset your password.
            </Form.Label>
          </Form.Group>
                    

          <Form.Group as={Row} className="p-1 d-flex justify-content-center" controlId="formHorizontalEmail">
              <Form.Label size="sm" column xs={2} sm={1} md={1} lg={1} xl={1} className="d-flex justify-content-end">Email</Form.Label>              
              
              <Col className="d-flex align-items-center justify-content-start" xs={8} sm={6} md={5} lg={4} xl={3} >
                <Form.Control 
                  ref={emailInputElement}
                  size="sm"
                  type="email"                        
                  placeholder=""
                  name="email"
                  isInvalid = {!!errors.email}
                  onFocus = {clearError}
                />                
              <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
              </Col>
          </Form.Group>          
          
          <Col xs={10} sm={8} md={4} lg={3} xl={4} className="d-flex justify-content-center">
            <Form.Group>            
              <div className="mt-3">
                <Button className="om-button black-gradient-hover-effect" variant="dark" onClick={handlePasswordReset}>Request Password Reset</Button>
              </div>  
            </Form.Group>
          </Col>                   

          <Form.Text as={Row} md={12} className="m-2 p-2 justify-content-center" id="responseText" >
            {message}
          </Form.Text>

          <div className="text-center mt-3">
              <hr style={{width:"85%" , margin: "0 auto"}}/>
          </div>
                
          <div className="d-flex justify-content-center align-items-center" style={{ fontSize : "0.9em"}}>
            <Button size="sm" className="p-1 m-1 om-link" variant="link" onClick={handleBack} style={{ textDecoration : "none"}}>Back to Login</Button>
          </div>
          
          
          
          
        </Form>
        </div>
    </>

  )
}

export default ForgotPassword