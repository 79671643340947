/* login.jsx */
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import MainLogoStatic from '../components/MainLogoStatic'

import TsParticles from "../components/ParticlesBackground";

import { api_config } from "../components/api_url";

import { PASSWORD_REGEX, EMAIL_REGEX, KEYWORDS_REGEX } from '../constants/regex';

const Register = () => {
    const api_url = api_config.url.API_URL
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);

    const firstnameInputElement = useRef();
    const lastnameInputElement = useRef();
    const emailInputElement = useRef();
    const passwordInputElement = useRef();
    const password2InputElement = useRef();
    const keywordsInputElement = useRef();
    const ndaCheckElement = useRef();

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
        
    const [validated, setValidated] = useState(false);
    const [FormSubmissionMessage, setFormSubmissionMessage] = useState("");

     //Set metadata
    useEffect(() => {        
        //Include Google Analytics Tag
        const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
        // Google Analytics tracking code
        const head = document.querySelector("head");
        const script1 = document.createElement("script");
        script1.async = true;
        script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
        head.appendChild(script1);
        
        const script2 = document.createElement("script");
        script2.innerText = 
            `window.dataLayer = window.dataLayer || [];\
            function gtag(){dataLayer.push(arguments);}\
            gtag('js', new Date()); \
            gtag('config', '${trackingID}');\
            gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
        head.appendChild(script2);

        // Update the document's meta tags when the component mounts
        document.title = 'Open Mortality | Register';
        document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality | Register');

        // Clean up the meta tag modifications when the component unmounts
        return () => {
            document.title = '';
            document.querySelector('meta[name="description"]').setAttribute('content', '');
            head.removeChild(script1);
            head.removeChild(script2);
        };
    }, []);
        
    const validateForm = () => {
        
        const newErrors = {}        

        if (!firstnameInputElement.current?.value || firstnameInputElement.current?.value === '') newErrors.firstname = 'Please enter first name'        
        if (!lastnameInputElement.current?.value || lastnameInputElement.current?.value === '') newErrors.lastname = 'Please enter last name'
                
        if (!emailInputElement.current?.value || emailInputElement.current?.value === '') newErrors.email = 'Please enter email'
        else if(!EMAIL_REGEX.test(emailInputElement.current?.value)) newErrors.email = 'Please enter valid email'

        if (!passwordInputElement.current?.value || passwordInputElement.current?.value === '') newErrors.password = 'Please enter valid password'        
        else if (!PASSWORD_REGEX.test(passwordInputElement.current?.value)) newErrors.password = 'Please enter valid password'                    

        if (!password2InputElement.current?.value || password2InputElement.current?.value === '') newErrors.password2 = 'Please enter valid password for confirmation'                
        else if (!PASSWORD_REGEX.test(password2InputElement.current?.value)) newErrors.password2 = 'Please enter valid password for confirmation'        
        else if (passwordInputElement.current?.value !== password2InputElement.current?.value) newErrors.password2 = 'Password not match'
        
        if (!keywordsInputElement.current?.value || keywordsInputElement.current?.value === '') newErrors.keywords = 'Please write something about your research'        
        else if (!KEYWORDS_REGEX.test(keywordsInputElement.current?.value)) newErrors.keywords = "Please input at least 5 keywords separated by space"
        
        if (!ndaCheckElement.current?.checked) newErrors.ndacheck = "Please check the box for Non-Disclosure Agreement"

        return newErrors
    }
    const handleSubmit = e => {
        
        setFormSubmissionMessage("")
        e.preventDefault();
        e.stopPropagation();
        const formsErrors = validateForm()
        
        if(Object.keys(formsErrors).length > 0 ){
            setErrors(formsErrors)
        }
        
        else {
            e.preventDefault();
            e.stopPropagation();            
            const requestOptions = {
                method: 'POST',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({                    
                    firstname: firstnameInputElement.current?.value,
                    lastname: lastnameInputElement.current?.value,
                    email: emailInputElement.current?.value,
                    password: passwordInputElement.current?.value,
                    keywords: keywordsInputElement.current?.value,
                    toc_agree: ndaCheckElement.current?.checked
                }),
            };
           
            fetch(api_url + "/user/signup2", requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    if (data.status)
                        //setFormSubmissionMessage(data.info.message)
                    navigate('/Welcome', {                        
                        state: {
                          message : data.info.message                          
                        },
                      })

                })
                .catch((err) => {
                    setFormSubmissionMessage("Problem on processing signup, please contact system administrator")
                });
        }
        setValidated(true);
    };
  

    /*const handleChange = e => {
        setFormSubmissionMessage("");
    };*/

    const handlebackToLogin = e => {
        navigate("/Login")
    }

    const clearError = () => {
        setErrors("")
        setValidated()
    }
    
    return (

        <>
            <TsParticles />
                       

            <div className="d-flex justify-content-center" style={{ touchAction : "pan-y", height : "100%" }}>
            <Modal className="text-start" show={show} onHide={handleClose} animation={true} centered>
                <Modal.Header closeButton className="p-4">
                    <Modal.Title>Non-Disclosure Agreement</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0 ms-3 me-3 mt-1 mb-1">
                <p>By checking the box, you are agreeing to the terms listed below:</p>
                <ol>
                <li>Data and other material provided by Open Mortality Portal (OM) shall not be redistributed or sold to other individuals, institutions or organizations.</li>
                <li>The user will not attempt to identify individuals, and there will be no use of the identity of any person or institution discovered inadvertently. Any such discovery will be reported immediately to OM.</li>
                <li>The user agrees to keep the data files in a secure location where they cannot be accessed by unauthorized users.</li>
                <li>Any publications employing data obtained from OM will cite the source, in line with the citation requirement provided with the dataset.</li>
                <li>OM and the relevant funding agencies bear no responsibility for the data’s use or interpretation or inferences based upon them.</li>
                <li>OM reserves the right to modify or discontinue, temporarily or permanently, the datasets found in OM at our sole discretion with or without prior notice to you.</li>
                </ol>

                </Modal.Body>        
            </Modal>
            <Form as={Row} className="justify-content-center align-items-center" style={{ zIndex : 1, width : "100%"}}>
            <Col>
            <Row className="m-3">
                <Col>
                    <MainLogoStatic />
                </Col>                
            </Row>

            <Form.Group as={Row} controlId="firstname" className="d-flex align-items-center justify-content-center">
                <Form.Label xs={10} sm={10} md={3} lg={2} xl={1} column className="d-flex justify-content-md-end justify-content-sm-start">First Name</Form.Label>            
                <Col className="" xs={10} sm={10} md={6} lg={4} xl={4} >
                    <Form.Control                         
                                ref={firstnameInputElement}
                                size="sm"
                                type="text"                        
                                placeholder=""
                                name="firstname"
                                isInvalid = {!!errors.firstname}
                                onFocus = {clearError}
                                required
                            />
                            <Form.Control.Feedback type='invalid'>{errors.firstname}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="lastname" className="d-flex align-items-center justify-content-center">                        
                        <Form.Label column xs={10} sm={10} md={3} lg={2} xl={1}  className="d-flex justify-content-md-end justify-content-sm-start">Last Name</Form.Label>            
                        <Col xs={10} sm={10} md={6} lg={4} xl={4} className="">
                        <Form.Control 
                            ref={lastnameInputElement}
                            size="sm"
                            type="text"                        
                            placeholder=""
                            name="lastname"
                            isInvalid = {!!errors.lastname}
                            onFocus = {clearError}
                            required
                        />                
                        <Form.Control.Feedback type="invalid">{errors.lastname}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} controlId="email" className="d-flex align-items-center justify-content-center">
                    <Form.Label column xs={10} sm={10} md={3} lg={2} xl={1}  className="d-flex justify-content-md-end justify-content-sm-start">Email</Form.Label>            
                        <Col xs={10} sm={10} md={6} lg={4} xl={4} className="">
                        <Form.Control 
                            required
                            ref={emailInputElement}
                            size="sm"
                            type="email"                        
                            placeholder=""
                            name="email"
                            pattern = {EMAIL_REGEX.source}
                            isInvalid = {!!errors.email}
                            onFocus = {clearError}
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="password" className="d-flex align-items-center justify-content-center">
						<Form.Label column xs={10} sm={10} md={3} lg={2} xl={1}  className="d-flex justify-content-md-end justify-content-sm-start">Password</Form.Label>            
                        <Col xs={10} sm={10} md={6} lg={4} xl={4} className="">                        
                        <Form.Control 
                            required
                            ref={passwordInputElement}
                            size="sm"
                            type="password"                        
                            placeholder=""
                            name="password"
                            pattern={PASSWORD_REGEX.source}
                            isInvalid = {!!errors.password}
                            onFocus = {clearError}
                        />                        
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="password2" className="d-flex align-items-center justify-content-center">                        
                        <Form.Label column xs={10} sm={10} md={3} lg={2} xl={1}  className="d-flex justify-content-md-end justify-content-sm-start text-lg-end">Confirm Password</Form.Label>            
                        <Col xs={10} sm={10} md={6} lg={4} xl={4} className="">                        						
                        <Form.Control 
                            required
                            ref={password2InputElement}
                            size="sm"
                            type="password"                        
                            placeholder=""
                            name="password2"
                            isInvalid = {!!errors.password2}
                            pattern={PASSWORD_REGEX.source}
                            onFocus = {clearError}                        
                        />                        
                        <Form.Control.Feedback type="invalid">{errors.password2}</Form.Control.Feedback>                        
                        </Col>
                    </Form.Group>

                    <Form.Text as={Row} id="passwordHelpBlock" muted className="mt-1 mb-4 d-flex align-items-center justify-content-center text-center">
                        <Col xs={10} sm={10} md={10} lg={7} xl={5} className="">                        						
                            Your password must be 8-20 characters long and contains at least one uppercase letter, one lowercase letter, and one number. 
                            You may also include one of the following symbols: <span style={{ color : "red"}}>!@#$%^&*</span><br /> Spaces, special characters, and emojis are not allowed.
                        </Col>                            
                    </Form.Text>

                    <Form.Group as={Row} controlId="keywords" className="d-flex align-items-center justify-content-center">                        
                        <Form.Label column xs={10} sm={10} md={3} lg={2} xl={1}  className="d-flex text-end justify-content-md-end justify-content-sm-start">Research Keywords</Form.Label>            
						<Col xs={10} sm={10} md={6} lg={4} xl={4} className="">                        						
                        
                        <Form.Control 
                            ref={keywordsInputElement}
                            as= "textarea"
                            rows={5}
                            size="sm"
                            type="text"                        
                            placeholder=""
                            name="email"                            
                            isInvalid = {!!errors.keywords && !KEYWORDS_REGEX.test(form.keywords)}
                            isValid={!!(!errors.keywords) && KEYWORDS_REGEX.test(form.keywords)}
                            onFocus = {clearError}
                            required
                        />
                        
                        <Form.Control.Feedback type="invalid">{errors.keywords}</Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} controlId="ndaCheck" className="mt-3 d-flex align-items-center justify-content-center">
                        <Col xs={10} sm={10} md={1} lg={1} xl={1} className="d-flex justify-content-center ">
                        
                        </Col>
                        <Col xs={10} sm={10} md={9} lg={8} xl={7} className="d-flex justify-content-center">                            
                        <Form.Check
                            required
                            ref={ndaCheckElement}
                            name="ndaCheck"
                            label={<Button className="om-link" size="sm" variant="link" onClick={handleShow} >By checking this box, you are agreeing to our Non Disclosure Agreement (NDA)</Button>}
                            isInvalid = {!!errors.ndacheck}
                            feedback={errors.ndacheck}
                            feedbackType="invalid"                            
                            onChange = {clearError}                            
                        />
                        <Form.Control.Feedback className="ps-2" type="invalid">{errors.ndaCheck}</Form.Control.Feedback>
                    </Col>

                    </Form.Group>
                    
                    <Form.Group as={Row} className="d-flex align-items-center justify-content-center pt-4">
                        <Col xs={10} sm={10} md={4} lg={3} xl={2} >
                            <div className="d-grid">
                                <Button className="om-button black-gradient-hover-effect" variant="dark" onClick={handleSubmit}>Register</Button>
                            </div>
                        </Col>
                    </Form.Group>
                

                    <div className="d-flex align-items-center justify-content-center pt-2">
                        <hr style={{ width : "80%"}}></hr>
                    </div>
                    
                    <div className="d-flex justify-content-center align-items-center" style={{ fontSize : "0.9em"}}>                        
                        <a href="/Login" style={{ textDecoration : "none"}}>Back to Login</a>
                    </div>


            </Col>
            </Form>

            </div>
            
            
            
                    
                
                
                    
                
            
            
        </>
    );
}

export default Register