import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
//import UserAccessControl from '../components/HomePageLogin'
import HeaderNew from "../components/HeaderNew";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Spinner from 'react-bootstrap/Spinner';

import { api_config } from "../components/api_url";

import { PASSWORD_REGEX } from '../constants/regex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const UserAccount = () => {
    const api_url = api_config.url.API_URL

    let loggedInUser = localStorage.getItem("user");
    let localCart = localStorage.getItem("cart");
    
    const currentPasswordInputElement = useRef();
    const passwordInputElement = useRef();
    const password2InputElement = useRef();

    let [cart, setCart] = useState([]);    
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const [accessToken, setAccessToken] = useState();
    //const [blockChangePassword, setblockChangePassword] = useState(true);
    const [userInfoUpdMsg, setUserInfoUpdMsg] = useState("");
    const [userPassswordUpdMsg, setUserPassswordUpdMsg] = useState("");
    const [updateDisable, setUpdateDisable] = useState(true)
    //const [btnUpdateDisable, setbtnUpdateDisable] = useState(true)

    const [loading, setLoading] = useState(true)

    const navigate = useNavigate();
    
    const [validated, setValidated] = useState(false);

    const setField = (field, value) => {      
      setForm({
          ...form,
          [field]:value
      })
      
      if (!!errors[field])
          setErrors({
              ...errors,
              [field]:null
          })      
    }

    useEffect(() => {
      //Include Google Analytics Tag
      const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
      // Google Analytics tracking code
      const head = document.querySelector("head");
      const script1 = document.createElement("script");
      script1.async = true;
      script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
      head.appendChild(script1);
      
      const script2 = document.createElement("script");
      script2.innerText = 
        `window.dataLayer = window.dataLayer || [];\
          function gtag(){dataLayer.push(arguments);}\
          gtag('js', new Date()); \
          gtag('config', '${trackingID}');\
          gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
      head.appendChild(script2);
      
      // Update the document's meta tags when the component mounts
      document.title = 'Open Mortality | Change Password';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - Change Password');
  
      // Clean up the meta tag modifications when the component unmounts
      return () => {
      document.title = '';
      document.querySelector('meta[name="description"]').setAttribute('content', '');
      head.removeChild(script1);
        head.removeChild(script2);
      };
    }, []);

    const validateUserPassword = () => {
      const newErrors = {}
      
      if (!currentPasswordInputElement.current?.value || currentPasswordInputElement.current?.value === '') newErrors.curr_password = 'Please enter a valid current password'              
      else if (!PASSWORD_REGEX.test(currentPasswordInputElement.current?.value)) newErrors.curr_password = 'Please enter valid current password'

      if (!passwordInputElement.current?.value || passwordInputElement.current?.value === '') newErrors.password = 'Please enter valid password'        
      else if (!PASSWORD_REGEX.test(passwordInputElement.current?.value)) newErrors.password = 'Please enter valid password'

      if (!password2InputElement.current?.value || password2InputElement.current?.value === '') newErrors.password2 = 'Please enter valid password for confirmation'                
      else if (!PASSWORD_REGEX.test(password2InputElement.current?.value)) newErrors.password2 = 'Please enter valid password for confirmation'        
      else if (passwordInputElement.current?.value !== password2InputElement.current?.value) newErrors.password2 = 'Password not match'
      
      return newErrors
    }

    const handleInput = (e, key) => {
      setUserInfoUpdMsg("")      
      setUpdateDisable(false)      
      setField(key, e.target.value)
    }

    const handleCancel = (e) => {      
      const UserInfo = JSON.parse(loggedInUser);      
      UserInfo.password = ""
      UserInfo.password2 = ""
      UserInfo.curr_password = ""      
      setUpdateDisable(true)
      setForm(UserInfo);
      setErrors({})
    }

    const handleLogin = () => {
      navigate("/Login")
    }

    const handleClose = () => setShow(false);
    
    const updateInfo = (type, updating_form) => {
      //console.log(updating_form)
      //User Info Update API
      
      fetch(api_url + '/user/' + updating_form.uid, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updating_form),
        })
        .then((res) => res.json())
        .then(result => {
            //console.log(result)
            if (type === "userinfo")
            {
              setUserInfoUpdMsg("User information updated")
              const updatedUser = JSON.parse(loggedInUser)
              for (const key of Object.keys(result.data))
              {
                //console.log(key + " : " + result.data[key])
                updatedUser[key] = result.data[key]
              }
              localStorage.setItem('user', JSON.stringify(updatedUser))
              loggedInUser = localStorage.getItem('user')              
              
            }
            else if(type === "password")
            {              
              //setblockChangePassword(true)
              form.password = ""
              form.password2 = ""
              form.curr_password = ""
              setForm(form)
              setUserPassswordUpdMsg(result.message)         
              setUpdateDisable(true)    
              setShow(true) 
            }
            //setbtnUpdateDisable(true)
        })
        .catch((err) => {
          if (type === "userinfo")  
            setUserInfoUpdMsg("Error on user information updating - " + err)
          else
            setUserPassswordUpdMsg("Error on user information updating - " + err)
        });
    }
    
    //Call refresh token before access to any resources  
    const refreshAccessToken = () => {

      var cur_user = JSON.parse(loggedInUser);    
      return new Promise(async (resolve, reject) => {
        //const accessToken = loggedInUser ? JSON.parse(loggedInUser).token : "";
        fetch(api_url + "/user/refresh", {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    //Authorization: "Bearer " + accessToken                    
                }
        })
        .then((res) => res.json())
        .then((myJson) => {            
            if (myJson.refresh)
            {
              cur_user['token'] = myJson.token;
              localStorage.setItem('user', JSON.stringify(cur_user));
            }            
            setAccessToken(myJson.token)
            resolve(myJson)
        });
      })    
    }

    const processPasswordUpdate = (e) => {
      setUserInfoUpdMsg("")
      e.preventDefault();
      e.stopPropagation();
      
      const formsErrors = validateUserPassword()
      if(Object.keys(formsErrors).length > 0 ){
          setErrors(formsErrors)
      }
      else {
        let form_updating = {};
        Object.keys(form).map( key => {
          //key these 4 entries for updating password
          if (((key === 'password') || (key === 'curr_password') || (key === 'uid') || (key === 'email') )) {
            form_updating[key] = form[key];
          }

        });
        updateInfo("password", form_updating)
      }
    }

    useEffect(() => {
      let at ="";
      const getAccesstoken = async () => {    
        
        at = await refreshAccessToken();
        
        if (at.token)
        {
          const UserInfo = JSON.parse(loggedInUser);
          delete UserInfo['token'];          
          setForm(UserInfo)
        }
        else
        {          
          loggedInUser = "";
        }
        setLoading(false)
      }    
        
      getAccesstoken()
        
      //turn it into js
      localCart = JSON.parse(localCart);    
      //load persisted cart into state if it exists
      if (localCart) setCart(localCart)

  }, []);

      const clearError = () => {
        setUserPassswordUpdMsg("")
        setErrors("")
        setValidated()
    }
  
  return (
    <>
        <HeaderNew CartCount={cart.length}></HeaderNew>
        
        <Modal centered show={show} onHide={handleClose} size="sm" fullscreen="sm-down">
          <Modal.Body className="d-flex justify-content-center">{userPassswordUpdMsg}</Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button variant="dark" className="om-button black-gradient-hover-effect" onClick={handleClose}>Close</Button>          
          </Modal.Footer>
        </Modal>          
        
        {!!accessToken
        ?
        <>
          <h4 className="d-flex align-items-center justify-content-center mt-5 mb-3" >Change Password</h4>
          <Form as={Row} className=" justify-content-center" validated={validated} noValidate style={{ width : "100%"}}>        
            
        <Col xs={10} sm={10} md={10} lg={8} xl={7}>
          <Form.Group as={Row} controlId="curr_password">
            <Form.Label column xs={12} sm={12} md={3} lg={5} xl={5} className="text-start text-md-end">Current Password</Form.Label>            
            <Col xs={12} md={7} lg={5} xl={5} className="mt-1 mb-1 justify-content-start">
              <Form.Control   
                size="sm"
                ref={currentPasswordInputElement}                              
                type="password" 
                placeholder=""
                name="curr_password"                 
                value = {form.curr_password}
                isInvalid = {!!errors.curr_password}
                onFocus = {clearError}
                onChange ={ e=> handleInput(e , "curr_password")}
                required 
              />
            
            <Form.Control.Feedback type="invalid">{errors.curr_password}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="password">            
            <Form.Label column xs={12} sm={12} md={3} lg={5} xl={5} className="text-start text-md-end">New Password</Form.Label>
            <Col xs={12} md={7} lg={5} xl={5} className="mt-1 mb-1 justify-content-start">
              <Form.Control    
                size="sm"               
                ref={passwordInputElement}
                type="password" 
                placeholder=""
                name="password"                 
                value = {form.password}
                isInvalid = {!!errors.password}
                onFocus = {clearError}               
                onChange ={ e=> handleInput(e , "password")}
                required 
              />
            
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} md="12" controlId="password2">
            
            <Form.Label column xs={12} sm={12} md={3} lg={5} xl={5} className="text-start text-md-end">Confirm New Password</Form.Label>
            <Col xs={12} md={7} lg={5} xl={5} className="mt-1 mb-1 justify-content-start">           
              <Form.Control    
                size="sm"
                ref={password2InputElement}              
                type="password" 
                placeholder=""
                name="password2"                
                value = {form.password2}
                isInvalid = {!!errors.password2}
                onFocus = {clearError}
                onChange ={ e=> handleInput(e , "password2")}
                required 
              />
            
            <Form.Control.Feedback type="invalid">{errors.password2}</Form.Control.Feedback>
            </Col>      
          </Form.Group>  
                   

          <Form.Text as={Row} id="passwordHelpBlock" muted className="d-flex justify-content-center text-center p-1">
            
            
            <Col xs={12} md={6} lg={8} className="text-center">
              Your password must be 8-20 characters long and contains at least one uppercase letter, one lowercase letter, and one number. 
              You may also include one of the following symbols: <span style={{ color : "blue"}}>!@#$%^&*</span><br />Spaces, special characters, and emojis are not allowed.
            </Col>                            
          </Form.Text>          

        <Form.Group as={Row} className="justify-content-center">
                <Col xs={11} sm={11} md={5} lg={5} xl={5} className="d-flex justify-content-center justify-content-md-end m-1">                  
                  <Button className="om-button black-gradient-hover-effect" disabled={updateDisable} variant="dark" onClick={ e => processPasswordUpdate(e)}>Update</Button>
                </Col>
                
                <Col xs={11} sm={11} md={5} lg={5} xl={5} className="d-flex justify-content-center justify-content-md-start m-1">
                  <Button className="om-button black-gradient-hover-effect" disabled={updateDisable} variant="dark" onClick={ e => handleCancel(e)}>Cancel</Button>
                </Col>
              </Form.Group>          
                   

         
      </Col>
      </Form>
      
      </>
      : !loading 
        ?
        <>
        <div className="d-flex justify-content-center">
          <Card className='info-display-card'>
            <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
              <Stack>
                <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} />
                <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>Session expired, please login to try again</ReactMarkdown>
              </Stack>
              <Button className="om-button" size="sm" variant='dark' style={{ textDecoration : "none"}} onClick={handleLogin}>Login</Button>
            </Card.Body>
          </Card>
        </div>
        </>
      : <>
        <div className="d-flex justify-content-center" style={{ marginTop : "50px"}}>
        <Spinner animation="border" role="status" >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        </div>
        </>
    }
    

    </>
  )
}

export default UserAccount