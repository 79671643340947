import React, {useEffect, useState } from "react";

import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import {Col, Row, Container} from 'react-bootstrap';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from "./pages/Home";
import DatasetDetails from "./pages/ViewDatasetDetailsNew";
import ViewDataset from "./pages/ViewDataset";
import Login from "./pages/Login";
import ErrorPage from "./pages/ErrorPage";
import Register from "./pages/Register";
import AccountVerify from "./pages/AccountVerify";
import ViewDatasetByName from "./pages/ViewDatasetByName";
import DownloadCart from "./pages/DownloadCart";
import UserInfo from "./pages/UserInfo";
import ChangePassword from "./pages/ChangePassword";
import Welcome from "./pages/Welcome";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import About from "./pages/About";
import Team from "./pages/Team";

import FeedbackButton from './components/FeedbackButton';
import Footer from './components/Footer';
import './App.css';

import "bootstrap/dist/css/bootstrap.min.css";
  
const App = () => {  
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {    
    localStorage.getItem("cart");
    localStorage.removeItem("geojson_preview");      
  }, []) 

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
    
  return (
      <Container fluid className="d-flex flex-column ps-0 pe-0 pt-0 pb-0 m-0" 
        style={{           
          height: `${height}px`,
          width : 'auto',
          margin: '0 auto',
          touchAction : "pan-y",
          overflowX : "hidden",
          overflowY : "auto"
        }}>        
                
        <Row className="flex-grow-1">          
          <Col className="p-0">
            {/*<FeedbackButton></FeedbackButton>*/}
            <Router>              
              <Routes>              
                <Route index path="/" element={<Home />} ></Route>                
                <Route path="/dataset" element={<ViewDataset />}></Route>
                <Route path="/dataset/:dataset" element={<ViewDatasetByName />}></Route>                              
                {/*<Route path="/data/:data_name/:tabname" element={<DatasetDetails />}></Route>*/}                
                <Route path="/data/:data_name/" element={<DatasetDetails />}></Route>                
                <Route path="/login" element={<Login />}></Route>
                <Route path="/register" element={<Register />}></Route>
                <Route path="/forgetpassword" element={<ForgetPassword />}></Route>
                <Route path="/resetpassword" element={<ResetPassword />}></Route>
                <Route path="/accountverify" element={<AccountVerify />}></Route>
                <Route path="/changepassword" element={<ChangePassword />}></Route>
                <Route path="/userinfo" element={<UserInfo />}></Route>
                <Route path="/downloadcart" element={<DownloadCart />}></Route>
                <Route path="/welcome" element={<Welcome />}></Route>                              
                <Route path="/about" element={<About />}></Route>                
                <Route path="/team" element={<Team />}></Route>
                <Route path="/ErrorPage" element={<ErrorPage />}></Route>                
                <Route path="*" element={<ErrorPage />}></Route>                
              </Routes>
            </Router>
          </Col>
        </Row>
        
        <Row className="d-flex justify-content-center align-items-center text-center ms-auto me-auto mt-4 mb-1" style={{ zIndex : 1}}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} >
            <Footer />            
          </Col>
        </Row>

        <ToastContainer 
            position="bottom-right"
            autoClose={2500}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={3}
            theme="light"            
        />
        
      </Container>
    );
  
}

export default App;