import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import { KEYWORDS_REGEX } from '../constants/regex';
import HeaderNew from "../components/HeaderNew";
import downloadjs from "downloadjs";

import { api_config } from "../components/api_url";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const DownloadCart = () => {  

  const navigate = useNavigate();
  const location = useLocation();

  const api_url = api_config.url.API_URL
  let arr_data_download = [];

  let localCart = localStorage.getItem("cart");  
  let [cart, setCart] = useState([]);
  
  const [downloadResult, setdownloadResult] = useState("");  
  const [isLoading, setIsLoading] = useState(false);  
  const [validated, setValidated] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const loggedInUser = localStorage.getItem("user");
  
  const [searchParams] = useSearchParams();
  
  const [value, setValue] = useState(localStorage.getItem('isLoading'));
  
  const [form, setForm] = useState({
    'purpose' : localStorage.getItem('purpose') || "",
    'keywords' : localStorage.getItem('keywords') || ""
  });
  const [errors, setErrors] = useState({});
  const setField = (field, value) => {    
    setForm({
        ...form,
        [field]:value
    })   

    localStorage.setItem(field, value)    
    
    if (!!errors[field])
        setErrors({
            ...errors,
            [field]:null
        })
  }

  //Set metadata
  useEffect(() => {
   //Include Google Analytics Tag
   const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
   // Google Analytics tracking code
   const head = document.querySelector("head");
   const script1 = document.createElement("script");
   script1.async = true;
   script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
   head.appendChild(script1);
   
   const script2 = document.createElement("script");
   script2.innerText = 
     `window.dataLayer = window.dataLayer || [];\
       function gtag(){dataLayer.push(arguments);}\
       gtag('js', new Date()); \
       gtag('config', '${trackingID}');\
       gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
   head.appendChild(script2);
    
    // Update the document's meta tags when the component mounts
    document.title = 'Open Mortality | Download Cart';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - Download Cart');

    // Clean up the meta tag modifications when the component unmounts
    return () => {
    document.title = '';
    document.querySelector('meta[name="description"]').setAttribute('content', '');
    head.removeChild(script1);
        head.removeChild(script2);
    };
  }, []);

  const handleCartItem = (item) => {
    //create a copy of our cart state, avoid overwritting existing state
    let cartCopy = [...cart];
        
    //assuming we have an ID field in our item
    let {ID} = item;
        
    //look for item in cart array
    let existingItem = cartCopy.find(cartItem => cartItem.ID === ID);
    
    //if item already exists
    if (existingItem) {        
      cartCopy.splice(cartCopy.indexOf(existingItem),1);
      toast(<div className="text-center align-items-center"><FontAwesomeIcon size="1x" icon={icon({name: 'circle-minus'})} />
      <span> Removed <strong>{existingItem.supp_info.data_name}</strong></span></div> )
    } else { //if item doesn't exist, simply add it
      cartCopy.push(item)
      toast(<div className="text-center align-items-center"><FontAwesomeIcon size="1x" icon={icon({name: 'circle-plus'})} />
      <span> Added <strong>{item.supp_info.data_name}</strong> </span></div> )
    }
    //update app state
    setCart(cartCopy)
    //make cart a string and store in local space
    let stringCart = JSON.stringify(cartCopy);
    localStorage.setItem("cart", stringCart)
  }

  const validateForm = () => {    
    const {purpose, keywords} = form
        const newErrors = {}
        if (!purpose || purpose === '') newErrors.purpose = 'Please enter research purpose'        
        
        if (!keywords || keywords === '') newErrors.keywords = 'Please enter some keywords about your research'
        else if (!KEYWORDS_REGEX.test(form.keywords)) newErrors['keywords'] = 'Please input at least 5 keywords separated by space'

        return newErrors
  }

  //Call refresh token before access to any resources  
  const refreshToken = () => {    
    var cur_user = JSON.parse(loggedInUser);    
    return new Promise(async (resolve, reject) => {
        const accessToken = loggedInUser ? JSON.parse(loggedInUser).token : "";
        fetch(api_url + "/user/refresh", {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json' 
                    //Authorization: "Bearer " + accessToken                    
                }
        })
        .then((res) => res.json())
        .then((myJson) => {            
            if (myJson.refresh)
            {
              cur_user['token'] = myJson.token;
              localStorage.setItem('user', JSON.stringify(cur_user));                      
            }
            resolve(myJson)
        });
    })    
  } 

  const handleFileDownload = async (event) => {
    
    const formsErrors = validateForm()

    if(Object.keys(formsErrors).length > 0 ) {      
      setErrors(formsErrors)
    }
    else if (!loggedInUser) {
      setdownloadResult ({ message : "Please login to download file"})
    }    
    else if (!cart.length > 0)
    {
      setdownloadResult ({ message : "No file in download cart"})
    }        
    else
    {
        setValidated(true);
        let filename = ""; 

        for (const downloadfile of cart) {
          if (downloadfile.supp_info.format.split(",").includes("csv"))
          {
            arr_data_download.push({id : downloadfile.ID , file : downloadfile.supp_info.data_name + ".csv" })
          }      
          if (downloadfile.supp_info.format.split(",").includes("geojson"))
          {
            arr_data_download.push({id : downloadfile.ID , file : downloadfile.supp_info.data_name + ".geojson"})
          }
          arr_data_download.push({id : downloadfile.ID , file : downloadfile.supp_info.data_name + "_datadict.csv" })
        }
        
        //Send download request
        return new Promise(async (resolve, reject) => {          
          const refreshTokenResult = await refreshToken();          
          //setdownloadResult(refreshTokenResult)          
          //console.log("Download started...")
          localStorage.setItem('isLoading', "true");
          setdownloadResult({ message : "Preparing the file(s)"})
          setIsLoading(true)
          //Clear cart and textarea
          resetCart()          
          let contentType;          
          fetch(api_url + "/data/download2", {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': "application/json",
              Authorization: "Bearer " + refreshTokenResult.token
            },         
            //responsetype: 'blob',
            body: JSON.stringify({ 
              downloadList : arr_data_download , 
              downloadInfo : { email : loggedInUser.email, purpose : form.purpose, keywords : form.keywords }, 
              download_id : uuidv4()
            })
          })
          .then((response) => {
              contentType = response.headers.get("Content-Type");
              if ((contentType) && (contentType.split(";")[0] === "application/json")) { 
                return response.json()
              }
              else if (contentType === "application/zip")
              {                
                filename = response.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"','');
                return response.blob()
              }
          })
          .then((result) => {            
            if ((contentType) && (contentType.split(";")[0] === "application/json")) {                
                resolve(result)
                setdownloadResult(result)
                setIsLoading(false)
                localStorage.setItem('isLoading', "false");
                setValidated(false)
              }
              else if (contentType === "application/zip")
              {                
                downloadjs(result, filename, "application/zip"); // Using downloadjs library                
                
                //Handling the file download without using downloadjs library                
                //const url = window.URL.createObjectURL(result);
                //const a = document.createElement('a');
                //a.href = url
                //a.download = filename;
                //a.click();
                
                //console.log("Download finished...")                
                //Set isLoading state and localstorage to false
                setIsLoading(false)
                localStorage.setItem('isLoading', "false");

                setdownloadResult({ message : "Your files are ready. You have been prompted to download them as a single zip file."})
                
                //console.log("isLoading(localstorage) = " +  localStorage.getItem('isLoading'))
                //console.log("isLoading               = " +  isLoading)
                resolve({ message : "Your files are ready. You have been prompted to download them as a single zip file."})
              }             
          })
        });
    }    
  }

  const handleViewData = (data) => {
    //navigate("/data/" + data.supp_info.data_name + "/variables", {      
    navigate("/data/" + data.supp_info.data_name , {      
      state: {        
        dataset : location.state ? location.state.dataset : "",
        data: data.supp_info,
        user : location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))
      },
    })
  }

  const handleGoback = () => {        
    navigate(-1, {      
      state: {        
        dataset : location.state ? location.state.dataset : "",
        data: location.state ? location.state.data : "",
        user : location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))
      },
    })
  }

  const updateSearchKeyword = (keyword) => {        
    setKeyword(keyword)        
  }

  useEffect(() => {    
      //Check load status every 2 second      
      if (isLoading === false && localStorage.getItem('isLoading') === "true")
      {
        const intervalId = setInterval(() => {          
          if (localStorage.getItem('isLoading') === 'false')
          {          
            setdownloadResult({ message : "The file preparation has finished, and you can now begin another download."})
            clearInterval(intervalId);
            setIsLoading(false)
          }
        }, 2000); // Check every 2 second    
      }
      
    }, [value]);
    

  useEffect(() => {
    //Get download status from localstorage
    localStorage.getItem('isLoading') === 'true' ? setIsLoading(true) : setIsLoading(false)
        
    //console.log("isLoading               = " + isLoading)
    //console.log("isLoading(localstorage) = " + localStorage.getItem('isLoading'))
    
    if (localStorage.getItem('isLoading') === 'true') 
      setdownloadResult({ message : "The file(s) from a previous download request are still being prepared. Kindly check your browser for the download prompt."})      

  }, []);

  useEffect(() => {    
    //turn it into js
    localCart = JSON.parse(localCart);    
    setCart(localCart)    
  },[])
  
  const resetCart = () => {
    
    //clear local cart
    setCart("")
              
    //Remove the cart storage in local storage
    localStorage.removeItem("cart")
    //Clear form and object
    setForm({ purpose : undefined, keywords : undefined }); 
    setValidated(false)

    localStorage.removeItem('purpose')
    localStorage.removeItem('keywords')
    
    //Remove form validation
    setValidated(false);
  }
    
  return (    
    <>
        {/*<UserAccessControl CartCount={cart ? carst.length : 0}/>*/}
        <HeaderNew CartCount={cart ? cart.length : 0} updateSearchKeyword={updateSearchKeyword}></HeaderNew>
        <Row className="mt-5 pt-2 justify-content-center" >
        
        <Col xs={10} sm={9} md={9} lg={8} xl={10} >
        
        <h3 className="d-flex justify-content-start m-0 p-2">{cart ? cart.length : 0} item(s) in download cart</h3>        
        {          
          
          cart && cart.length > 0
          ? <>
            <div className="p-2 align-items-center justify-content-center" style={{ width : "100%"}}>            
            {              
              cart.map( (cartitem, index) => {
                return (
                <>
                  <Row key={uuidv4()}>
                    <Col>
                    <Row >
                      <Col xs={12} md={12} lg={12} className="m-0 p-0 ">
                        <Button className="m-0 d-flex justify-content-start om-link" variant="link" onClick={()=>handleViewData(cartitem)} style={{ textDecoration : "none"}}>                          
                          <strong>{index + 1 + "."} {cartitem.supp_info.title ? cartitem.supp_info.title : cartitem.supp_info.data_name}</strong>
                        </Button>                        
                      </Col>
                    </Row>
                  
                    <Row >
                      <Col xs={10} md={6} lg={5}>
                        <Stack direction="vertical" gap={1} >
                            
                          <div className="text-start" style={{ wordBreak: "break-word", overflowY : "hidden", textOverflow: 'ellipsis' }}>
                              {
                                //cartitem.supp_info.data_name + ".csv"
                                
                                cartitem.supp_info.format.split(",").includes("csv")
                                ? <><div className="text-start" 
                                  style={{ wordBreak: "break-word", overflowY : "hidden", textOverflow: 'ellipsis' }} >{cartitem.supp_info.data_name + ".csv"}
                                </div>
                                
                                </>
                                : <></>                                
                              }
                              {
                                cartitem.supp_info.format.split(",").includes("geojson")
                                ? <>
                              <div className="text-start" 
                                style={{ wordBreak: "break-word", overflowY : "hidden", textOverflow: 'ellipsis' }} >{cartitem.supp_info.data_name + ".geojson"}</div>
                                </>
                                :<></>
                              }
                              <div className="text-start" style={{ wordBreak: "break-word", overflowY : "hidden", textOverflow: 'ellipsis' }} >{cartitem.supp_info.data_name + "_datadict.csv"}</div>
                          </div>
                            
                        </Stack>

                      </Col>

                      <Col xs={2} md={1} lg={1} className="d-flex justify-content-center">
                        <FontAwesomeIcon onClick={()=>handleCartItem(cartitem)} style={{ cursor: "pointer"}} className='' size="lg" icon={icon({name: 'trash-can'})} />                        
                      </Col>
                    </Row>
                    </Col>
                  </Row>
                </>
              )})
            }      
            </div>      
          </>          
          : <></>
        }
          
        <Stack className="p-0 mt-3 ms-0 mb-0 d-flex justify-content-start" direction="vertical" gap={3}>
          {/*<textarea className="m-0 p-2" defaultValue="What is the purpose of downloading the data?" 
            onChange={(e) => handlePurposeChange(e.target.value)} style={{ height: '120px' }}></textarea>
          <textarea className="m-0 p-2" defaultValue="Research Keywords"  onChange={(e) => handleKeywordsChange(e.target.value)} style={{ height: '120px' }}></textarea>*/}
          <Form validated={validated} noValidate>            
            <Form.Group as={Col} md="12" controlId="purpose">
              <Form.Label column="md" className="col-auto d-flex align-content-start " style={{ fontWeight : "bold"}} >Research Purpose</Form.Label>
              <Form.Control 
                required
                name="purpose"
                as="textarea"                  
                type="text"
                placeholder="Brief description on the purpose of the data in your research" 
                value = {form.purpose || ""}
                isInvalid = {!!errors.purpose}
                onChange={(e) => setField('purpose', e.target.value)}
                disabled = {!!isLoading || !!(!cart)}
                rows={4}
              /> 
              <Form.Control.Feedback type="invalid">{errors.purpose}</Form.Control.Feedback>            
            
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="keywords">                            
              <Form.Label column="md" className="col-auto d-flex align-content-start" style={{ fontWeight : "bold"}}>Research Keywords</Form.Label>
              <Form.Control 
                required
                name="keywords"
                as="textarea" 
                type="text" 
                placeholder="Set of Keywords for your research separated by commas" 
                value = {form.keywords || ""}
                isInvalid = {!!errors.keywords}
                onChange={(e) => setField('keywords', e.target.value)}
                disabled = {!!isLoading || !!(!cart)}
                rows={4}
              />
            <Form.Control.Feedback type="invalid">{errors.keywords}</Form.Control.Feedback> 
            </Form.Group> 
          </Form>
          </Stack>

          <Form.Group as={Row} className="mt-2 justify-content-center">
            <Col xs={11} sm={11} md={5} lg={5} xl={5} className="m-1 ps-1 pe-1 d-flex justify-content-center justify-content-md-end">
                <Button className="om-button black-gradient-hover-effect" variant="dark" id="button-browse" disabled={isLoading} onClick={ () => handleGoback()} >Browse more data</Button>
            </Col>
                
            <Col xs={11} sm={11} md={5} lg={5} xl={5} className="m-1 ps-1 pe-1 d-flex justify-content-center justify-content-md-start">
              <Button className="om-button black-gradient-hover-effect" variant="dark" id="button-download-cart" disabled={isLoading || !!(!cart)} onClick={() => handleFileDownload()} >Download</Button>
            </Col>
          </Form.Group>
          
          {
            isLoading === true
            ? <>
                <div className="d-flex align-items-center justify-content-center text-center m-3">
                  <span>
                    <Spinner size="sm" animation="border" role="">
                      <span className="visually-hidden">{downloadResult.message}</span>
                    </Spinner> {downloadResult.message}
                  </span>
                </div>
              </>
            : <><div className="text-center"><span>{downloadResult.message}</span></div></>
          }
                  
        
        </Col>
        </Row>
    </>
  )
}

export default DownloadCart