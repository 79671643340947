import React from 'react'
import { useNavigate } from 'react-router-dom';
import {Button, Row, Col} from 'react-bootstrap';

//import MagnifyingGlass from '../Assets/images/magnifying_glass_white.png'

import "./ExploreOurDataset.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const ExploreOurDataset = () => {
  
  const navigate = useNavigate()

  const strloggedInUser = localStorage.getItem("user");
  const loggedInUser = JSON.parse(strloggedInUser)

  const handleExplore = () => {
    loggedInUser
    ? navigate("/dataset")
    : navigate("/login")
  }

  return (
    <>
      <Row className='fade-in'>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex justify-content-center align-items-center'> 
          <Button 
            variant='dark'
            className="explore-dataset red-gradient-hover-effect"
            id="button-explore-dataset"
            onClick={handleExplore}
          >
            <div className='m-1 d-flex align-items-center explore-dataset-button'>
            <span className='m-2'>Explore Our Datasets </span>
              <FontAwesomeIcon className='fa-flip-horizontal me-2' size="1x" icon={icon({name: 'magnifying-glass'})} />            
            </div>
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ExploreOurDataset