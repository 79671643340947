//Landing page of the OMP
import React, { useEffect, useState } from 'react'
//import DatasetSearchBox from '../components/DataSearch';
import { useNavigate } from 'react-router-dom';

import {Col , Row, Button, Container} from 'react-bootstrap';

import MainLogoAnimated from '../components/MainLogoAnimated'
import OmpSlogan from '../components/OmpSlogan'
import SubFooter from '../components/SubFooter';
//import HomePageLogin from '../components/HomePageLogin'
import ExploreOurDataset from '../components/ExploreOurDataset';

import TsParticles from "../components/ParticlesBackground";

const Home = () => {
    const navigate = useNavigate();    

    //Set metadata, header
    useEffect(() => {      
      //Include Google Analytics Tag
      const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
      // Google Analytics tracking code
      const head = document.querySelector("head");
      const script1 = document.createElement("script");
      script1.async = true;
      script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
      head.appendChild(script1);
      
      const script2 = document.createElement("script");
      script2.innerText = 
          `window.dataLayer = window.dataLayer || [];\
          function gtag(){dataLayer.push(arguments);}\
          gtag('js', new Date()); \
          gtag('config', '${trackingID}');\
          gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
      head.appendChild(script2);            

      // Update the document's meta tags when the component mounts
      document.title = 'Open Mortality | Help the Living, Study the Dead';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - Home');

      // Clean up the meta tag modifications when the component unmounts
      return () => {
        document.title = '';
        document.querySelector('meta[name="description"]').setAttribute('content', '');      
        head.removeChild(script1);
        head.removeChild(script2);      
      };      
    }, []);

      useEffect(() => {        
        localStorage.removeItem('geojson_preview')
      }, [])

      const goToOMPAbout = () => {
        navigate("/About")
      }

    return (        
      <>        
        <TsParticles />
        <Container fluid className='d-flex flex-column justify-content-between' style={{ height: "100%", width: "auto", zIndex : 2}}>
          
        <Row className="mt-3 ps-3 d-flex align-items-start justify-content-start" style={{ zIndex : 2 }}>
	        <Col className="d-flex justify-content-start" >                  
		        <a href='/about' className='about-button about-button-hover-effect' >About</a>
            <a href='/team' className='about-button about-button-hover-effect' >Team</a>
	        </Col>
        </Row>

        <Row className='d-flex justify-content-center align-items-center' style={{ zIndex : 2 }}>
          <Col >
            <MainLogoAnimated />
            <OmpSlogan />	
            <ExploreOurDataset></ExploreOurDataset>                
          </Col>
        </Row>       

        <Row style={{ zIndex : 1}}>
          <Col>                
            <SubFooter />                  
          </Col>
        </Row>                    

        </Container>        
      </>        
    )
}

export default Home