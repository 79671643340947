import React, {useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import ListDatasets from '../components/ListDataset';
import HeaderNew from '../components/HeaderNew';

const ViewDataset = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [keyword, setKeyword] = useState(searchParams.get('keyword'));
    const [cart, setCart] = useState([]);
        
    let localCart = localStorage.getItem("cart");

    const strloggedInUser = localStorage.getItem("user");
    const loggedInUser = JSON.parse(strloggedInUser)    

    //Set metadata
    useEffect(() => {
      //Include Google Analytics Tag
      const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
      // Google Analytics tracking code
      const head = document.querySelector("head");
      const script1 = document.createElement("script");
      script1.async = true;
      script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
      head.appendChild(script1);
      
      const script2 = document.createElement("script");
      script2.innerText = 
          `window.dataLayer = window.dataLayer || [];\
          function gtag(){dataLayer.push(arguments);}\
          gtag('js', new Date()); \
          gtag('config', '${trackingID}');\
          gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
      head.appendChild(script2);

      // Update the document's meta tags when the component mounts
      document.title = 'Open Mortality | Dataset';
      document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - Dataset');

      // Clean up the meta tag modifications when the component unmounts
      return () => {
        document.title = '';
        document.querySelector('meta[name="description"]').setAttribute('content', '');
        head.removeChild(script1);
        head.removeChild(script2);
      };
  }, []);
    
    useEffect(()=>{
      //turn cart content from locat storage into js
      localCart = JSON.parse(localCart);      
      //load persisted cart into state if it exists
      if (localCart) setCart(localCart)
    }, [])

    useEffect(() => {        
        localStorage.removeItem('geojson_preview')
    }, [])

    return (
        <>          

            <HeaderNew CartCount={cart.length} ></HeaderNew>
            
            {/*<UserAccessControl2 CartCount={cart.length}/>            */}
            {/*<SearchResultHeader updateSearchKeyword={updateSearchKeyword} />*/}
                        
            <ListDatasets updateSearchKeyword={keyword} />
        </>
    )
}

export default ViewDataset