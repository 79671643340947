import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom';
import {Col, Row, Stack, Badge, Button} from 'react-bootstrap';

import { api_config } from "./api_url";

import { DATA_MD_CITATION_EXTRACT_REGEX, DATA_MD_ABOUT_EXTRACT_REGEX } from '../constants/regex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import "./datasetInfo.css"

const DatasetInfo = ({viewing_dataset}) => {
    const api_url = api_config.url.API_URL;
    const [dsInfo, setDsInfo] = useState();
    const [mdDsDescription, setmdDsDescription] = useState("");       

    const memoizedResult = useMemo(() => {        
        return viewing_dataset 
            ? viewing_dataset.length > 0 
                ? viewing_dataset 
                : undefined 
            : undefined
    }, []);
    
    const params = useParams();
    
    const getDatasetByName = async () => {
        //console.log("****getDatasetByName@DatasetInfo");
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/dataset/" + encodeURIComponent(params.dataset), {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            })
            .then((res) => res.json())
            .then(function (myJson) {                
                resolve(myJson);
            });
        });
      };

     
    useEffect(() => { 
        const getDatsetInfo = async () => {
            //console.log("No Daatset Data passed from parent... getDatasetByName()")
            const ds = await getDatasetByName()
            
            if (!!ds.data)
            {                
                setDsInfo(ds.data);
                setmdDsDescription(ds.data.dataset_desc)
            }
        }        
        
        if (viewing_dataset.length === 0 ) {
            getDatsetInfo()
        } else {
            setDsInfo(viewing_dataset[0])
            setmdDsDescription(viewing_dataset[0].dataset_desc)
        }

    }, [])    
    
    if ( (!!memoizedResult === true) || (!!dsInfo === true) )
    {
        //Extract contact info from description
        //const contactPattern = /## Contact([\s\S]*?)(?=$)/;
        //console.log(mdDsDescription.match(contactPattern))
        return (        
            <>
                <div className="ms-1 me-1 mt-2 mb-4">

                 <Row className='mb-2'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex align-items-center justify-content-start datasetInfo-title' >
                        {memoizedResult ? memoizedResult[0].title : dsInfo.title} ({memoizedResult ? memoizedResult[0].title_abbr.toUpperCase() : dsInfo.title_abbr.toUpperCase()})
                    </Col>                    
                </Row>      
                
                <Row className='mb-2'>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3} className='d-flex align-items-center justify-content-start datasetInfo-publish-date' >
                        <div >
                            <FontAwesomeIcon size="1x" icon={icon({name: 'calendar', style:'regular'})} />
                            <span> {memoizedResult ? memoizedResult[0].publish_date.slice(0,10) : dsInfo.publish_date.slice(0,10)}</span>
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={5} lg={5} xl={5} className='d-flex align-items-center justify-content-start' >
                        <a href={memoizedResult ? `mailto:${memoizedResult[0].contact}` : `mailto:${dsInfo.contact}`} className='datasetInfo-contact'>
                            <FontAwesomeIcon size="1x" icon={icon( {name : "envelope", style:'regular'} )} />        
                            <span> {memoizedResult ? memoizedResult[0].contact : dsInfo.contact}</span>
                        </a>
                    </Col>
                </Row>                
                
                <Row className='pt-2'>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ReactMarkdown
                            className='datasetInfo-md-content'
                            remarkPlugins={[remarkGfm]} 
                            children = {
                                mdDsDescription.match(DATA_MD_ABOUT_EXTRACT_REGEX) 
                                    ? mdDsDescription.match(DATA_MD_ABOUT_EXTRACT_REGEX)[0].trim()                
                                    : mdDsDescription
                                }
                    >                        
                    </ReactMarkdown>
                </Col>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {
                        (memoizedResult ? memoizedResult[0].tag : dsInfo.tag).split(",").map( (t, index) => {
                            return (<Badge className="me-2 mt-1 ps-2 pe-2" key={t + "_" + index} bg="dark">{t}</Badge>)
                        })
                    }
                    </Col>
                </Row>                
                </div>
            </>        
        )
    }
    else {
        return(
            <>                
            </>
        )        
    }    
}

export default DatasetInfo