import React, { useEffect, useState, Profiler } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {Col, Row, Button, OverlayTrigger, Tooltip, Card, Stack, Badge } from "react-bootstrap";

import Form from 'react-bootstrap/Form';

import Spinner from 'react-bootstrap/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import DatasetInfo from "../components/DatasetInfo";
import HeaderNew from "../components/HeaderNew";

import { api_config } from "../components/api_url";

import { useSpring, animated } from '@react-spring/web';

import { toast } from "react-toastify";

import { DATA_MD_CITATION_EXTRACT_REGEX } from '../constants/regex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ViewDatasetByName = () => {  
  const api_url = api_config.url.API_URL
  
  const [dataset, setDataset] = useState([]);      
  
  const [dataInDataset, setDataInDataset] = useState([]);

  const [filteredInput, setFilteredInput] = useState("");
  const [filteredDataInDataset, setFilteredDataInDataset] = useState([]);

  const [user, setUser] = useState();
  const [accessToken, setAccessToken] = useState();   
  const [loading, setLoading] = useState(true)  

  const params = useParams();  
  const location = useLocation();
  const navigate = useNavigate();

  //Cart
  let [cart, setCart] = useState([]);
  let localCart = localStorage.getItem("cart");  
  const loggedInUser = localStorage.getItem("user");

  //Set metadata
  useEffect(() => {    
    //Include Google Analytics Tag
    const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
    // Google Analytics tracking code
    const head = document.querySelector("head");
    const script1 = document.createElement("script");
    script1.async = true;
    script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
    head.appendChild(script1);
    
    const script2 = document.createElement("script");
    script2.innerText = 
        `window.dataLayer = window.dataLayer || [];\
        function gtag(){dataLayer.push(arguments);}\
        gtag('js', new Date()); \
        gtag('config', '${trackingID}');\
        gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
    head.appendChild(script2);

    // Update the document's meta tags when the component mounts
    document.title = 'Open Mortality | ' + params.dataset;
    document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality Dataset - ' + params.dataset);

    // Clean up the meta tag modifications when the component unmounts
    return () => {
      document.title = '';
      document.querySelector('meta[name="description"]').setAttribute('content', '');
      head.removeChild(script1);
      head.removeChild(script2);
    };
  }, []);


  useEffect( () => {
    //Get dataset Info
    let ds;
    const getDatasetInfo = async () => {      
      ds = await getDatasetInfoByTitleAbbr()      
      setDataset(ds)
    }    
    
    if (!location.state) getDatasetInfo()
  },[])
  
  useEffect(() => {           
    const getDataInDataset = async () => {      
      const dataInDataset = await getDataInDatasetByDatasetAbbr();
      
      //Set default display order
      const categoryOrder = ['MAIN', 'SUPPLEMENTARY'];

      //Sort the data in dataset
      dataInDataset.data.sort((a, b) => {
        const categoryIndexA = categoryOrder.indexOf(a.category.toUpperCase());
        const categoryIndexB = categoryOrder.indexOf(b.category.toUpperCase());
        // Compare the category indices
        if (categoryIndexA < categoryIndexB) {
          return -1;
        } else if (categoryIndexA > categoryIndexB) {
          return 1;
        } else {
          // If categories are the same or not in the categoryOrder array, sort by ID
          return a.id - b.id;
        }
      });      
      setDataInDataset(dataInDataset)
      setFilteredDataInDataset(dataInDataset)
    };    
    
    //Get all the data in the selected dataset    
    getDataInDataset();
  }, [])

  /*useEffect(() => {
    localStorage.removeItem('geojson_preview')
  }, [])*/

  useEffect(() => {    
    //Set user 
    setUser(JSON.parse(localStorage.getItem("user")))
  },[])

  useEffect(() => {        
    //turn the cart content in local storage into js
    localCart = JSON.parse(localCart);
    //load persisted cart into state if it exists
    if (localCart) setCart(localCart)    
    //Get all the data under the dataset    
  }, []);

  useEffect(() => {    
    //Data filerting    
    if (Object.keys(dataInDataset).includes('data'))
    {       
      // Create a copy of filteredDataInDataset to update
      let updatedFilteredDataInDataset = { ...dataInDataset }; 
      const filterkeyword = filteredInput.trim().toUpperCase();
      const filteredData = dataInDataset.data.filter((item) => {
          return (
            (item.data_name.toUpperCase().includes(filterkeyword)) ||
            (item.tag.toUpperCase().includes(filterkeyword)) ||
            (item.data_desc.toUpperCase().includes(filterkeyword))
          )
      })
      updatedFilteredDataInDataset.data = filteredData
      setFilteredDataInDataset(updatedFilteredDataInDataset)      
    }
}, [dataInDataset, filteredInput]);
  
  const viewDataDetail = async (data_viewing) => {
    const accessToken = await refreshToken();
    setAccessToken(accessToken)

    navigate("/data/" + data_viewing.data.data_name + "?pageSize=10&climit=5&page=1", {
      //+ "/variables"
      state: {        
        data: data_viewing.data,
        dataset: location.state ? location.state.dataset : data_viewing.dataset,
        user : accessToken.token 
          ? accessToken.token.refresh 
            ? JSON.parse(localStorage.getItem("user"))
            : data_viewing.user 
          : data_viewing.user
      },
    } );
  };

  const handleNonExistenceDS = () => {
    navigate('/ErrorPage')
  }
 
  const refreshToken = () => {    
    var cur_user = JSON.parse(loggedInUser);
    return new Promise(async (resolve, reject) => {
        //const accessToken = loggedInUser ? JSON.parse(loggedInUser).token : "";
        fetch(api_url + "/user/refresh", {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    //Authorization: "Bearer " + accessToken                    
                }
        })
        .then((res) => res.json())
        .then((myJson) => {            
            if (myJson.refresh)
            {
              cur_user['token'] = myJson.token;
              localStorage.setItem('user', JSON.stringify(cur_user));                      
            }            
            resolve(myJson)
        });
    })    
  }
 
  const getDatasetInfoByTitleAbbr = async () => {
    return new Promise(async (resolve, reject) => {
        fetch(api_url + "/dataset/" + encodeURIComponent(params.dataset), {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        .then((res) => res.json())
        .then((datasetInfo) => {            
            if (datasetInfo.data.length > 0)            
              resolve(datasetInfo.data);            
            else            
              resolve(datasetInfo);
        });
    });
  };

  const getDataInDatasetByDatasetAbbr = async () => {
    //Refresh access token
    const accessToken = await refreshToken();    
    setAccessToken(accessToken)

    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/dataset/" + params.dataset.toLowerCase() + "/data", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => res.json())
      .then(function (myJson) {          
        setLoading(false);  
        resolve(myJson);          
        });
    });    
  };

  const handleDownload = (item) => {            
    //create a copy of our cart state, avoid overwritting existing state    
    let cartCopy = [...cart];        
    //assuming we have an ID field in our item
    let {ID} = item;        
    //look for item in cart array
    let existingItem = cartCopy.find(cartItem => cartItem.ID === ID);    
    //if item already exists
    if (existingItem) {        
      cartCopy.splice(cartCopy.indexOf(existingItem),1);              
      toast(<div className="text-center align-items-center"><FontAwesomeIcon size="1x" icon={icon({name: 'circle-minus'})} />
      <span> Removed <strong>{existingItem.supp_info.data_name}</strong></span></div> )
    } else if (cartCopy.length >= 10) {      
      toast(<div className="text-center align-items-center" style={{ color : "red"}}><FontAwesomeIcon size="1x" icon={icon({name: 'triangle-exclamation'})} />
      <span> Limit of 10 items per download has been reached.</span></div> )
    }
    else { //if item doesn't exist, simply add it
      cartCopy.push(item);      
      toast(<div className="text-center align-items-center"><FontAwesomeIcon size="1x" icon={icon({name: 'circle-plus'})} />
      <span> Added <strong>{item.supp_info.data_name}</strong> </span></div> )
    }
    //update app state
    setCart(cartCopy)
    //make cart a string and store in local space
    let stringCart = JSON.stringify(cartCopy);
    localStorage.setItem("cart", stringCart)    
  }  

  const handleFilterChange = (e) => {    
    setFilteredInput(e.target.value)
  }

  //Extract Citation and display
  const CitationDisplay = ({data_desc_md}) => {
    return (
      <>
        <div className="p-2 m-2">
        <ReactMarkdown 
          remarkPlugins={[remarkGfm]}
          children = {
            data_desc_md[0].dataset_desc.match(DATA_MD_CITATION_EXTRACT_REGEX) 
              ? data_desc_md[0].dataset_desc.match(DATA_MD_CITATION_EXTRACT_REGEX)[1].trim() 
              : "_No Citation available_"
            }
        >
        </ReactMarkdown>                        
        </div>
      </>
    )
  }
  
  //render table showing `data` in selected dataset
  const DataList = () => {    
    return(
    loading 
      ? <>
        <div className="d-flex justify-content-center" style={{ marginTop : "50px"}}>
          <Spinner animation="border" role="status" >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
        </>
      : (Object.keys(filteredDataInDataset).includes('data') && filteredDataInDataset.data.length > 0 )
        ? <>        
          <animated.div style={{...props}}>
            {
              filteredDataInDataset.data.map((d, dataIndex) => (
                <Card key={d.data_id} className="ms-0 me-0 mt-3 mb-3 p-0 data-listing-card shadow-sm">
                  <Card.Body className="data-listing-card-body">                  
                    <Row className="mt-0 mb-0 ms-0 me-0 p-0">                
                      <Card.Title className="data-listing-card-title mb-3" >                    
                        {/* Title */}
                        <span>{!!d.title ? d.title : d.data_name}</span><br />
                      </Card.Title>
                  
                      {/* Data Description*/}
                      <Col xs={10} sm={10} md={11} lg={11} xl={11} >                    
                        <ReactMarkdown 
                          remarkPlugins={[remarkGfm]}
                          children={d.data_desc}                    
                        />                        
                      </Col>               
                
                      {/* Download button (icon) */}
                      <Col xs={2} sm={2} md={1} lg={1} xl={1} className="d-flex align-items-center justify-content-md-center justify-content-sm-end">
                
                    {(() => {       
                    //No login user;
                    if (!!user === false) {
                      return (
                        <OverlayTrigger            
                          overlay={<Tooltip id="tooltip-disabled">Please login to view the data</Tooltip>}>
                          <span className="d-inline-block">
                            <Button className="add-to-cart-button" id={d.data_name} disabled={!user}>                              
                              <FontAwesomeIcon size="1x" icon={icon({name: 'lock'})} />
                            </Button>
                          </span>
                        </OverlayTrigger>
                      )
                    }             
                    //Session expired;
                    else if ((!!user) && (accessToken.token === false)) {
                      return (
                        <OverlayTrigger            
                          overlay={<Tooltip id="tooltip-disabled">{accessToken.message}</Tooltip>}>
                          <span className="d-inline-block">
                            <Button className="add-to-cart-button" 
                              variant="outline secondary" 
                              id={d.data_name} 
                              disabled={!accessToken.token}>                              
                              <FontAwesomeIcon size="1x" icon={icon({name: 'lock'})} />
                            </Button>
                          </span>
                        </OverlayTrigger>
                      )
                    }                    
                    else if( (!!user) && (!!accessToken.token) && (!d.allowed_access)) {
                      //Permission denied;
                      return (
                        <OverlayTrigger            
                          overlay={<Tooltip id="tooltip-disabled">
                              {d.message}
                          </Tooltip>}>
                          <span className="d-inline-block">                          
                          <Button                            
                            className="add-to-cart-button"                             
                            id={d.data_name} 
                            disabled ={!d.allowed_access}
                            >                          
                            {/*<img src={prohibited_icon} alt="download prohibited" height="20px" width="20px" />*/}
                            <FontAwesomeIcon size="1x" icon={icon({name: 'lock'})} />
                          </Button>
                          </span>
                        </OverlayTrigger>
                      )
                    }
                    if ((!!accessToken.token) && (d.allowed_access)) {
                      //Access grant;
                      return (
                      <Button
                        className="add-to-cart-button"
                        id={d.data_name} onClick={() => handleDownload({ID : d.data_id, supp_info : d})}
                        disabled={d.allowed_access ? false : true}>{
                          cart.find(cartItem => cartItem.ID ===  d.data_id)
                            ? 
                               <OverlayTrigger            
                                    overlay={<Tooltip id="tooltip-disabled">
                                      Remove {d.data_name} from download cart
                                  </Tooltip>}>
                                  <FontAwesomeIcon size="lg" icon={icon({name: 'circle-minus'})} /> 
                                </OverlayTrigger>
                                
                              
                              : <OverlayTrigger            
                                  overlay={<Tooltip id="tooltip-disabled">
                                  Add {d.data_name} to download cart
                                </Tooltip>}>
                                    <FontAwesomeIcon size="lg" icon={icon({name: 'circle-plus'})} />                                 
                                </OverlayTrigger>                              
                          }                      
                      </Button>
                      )
                    }
                    else {
                      return (
                        <OverlayTrigger            
                          overlay={<Tooltip id="tooltip-disabled">
                              There is error on getting data permission
                          </Tooltip>}>
                          <span className="d-inline-block">                          
                          <Button
                            className="add-to-cart-button" 
                            variant="outline-dark" 
                            id={d.data_name} 
                            disabled="true"
                            >                          
                            {/*<img src={prohibited_icon} alt="download prohibited" height="20px" width="20px" />*/}
                            <FontAwesomeIcon size="1x" icon={icon({name: 'lock'})} />
                          </Button>
                          </span>
                        </OverlayTrigger>
                      )
                    }
                })()}

                </Col>

                  <Col xs={12} sm={6} md={5} lg={4} xl={4}>
                      <div className="d-grid">
                      <Button className="om-button black-gradient-hover-effect" variant="dark" onClick={() => viewDataDetail({data : d , user : user})}>
                          <span className="ps-1 pe-1 ms-1">View Data </span>
                          <FontAwesomeIcon size="1x" icon={icon({name: 'arrow-right'})} />
                      </Button>
                      </div>
                  </Col>                
                </Row>
                </Card.Body>                
              </Card>
              
              ))
              }            
            
          </animated.div>     
          </>
        : Object.keys(filteredDataInDataset).includes('message')
          ?
          <>
          <div className="d-flex align-items-center justify-content-center mt-3">
            <Card key="no-data-in-dataset" className='info-display-card'>
              <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                <Stack>
                  <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} />
                  <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-3'>{filteredDataInDataset.message}</ReactMarkdown>                            
                </Stack>
              </Card.Body>
            </Card>
          </div>
          </>
          : 
          <div className="d-flex justify-content-center mt-3">
          <Card key="no-data-in-dataset" className='info-display-card'>
            <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
            <Stack>
              <FontAwesomeIcon size="2x" icon={icon({name: 'circle-info'})} />
              <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-3'>{`No data found for keyword(s): ***_${filteredInput}_***`}</ReactMarkdown>                            
            </Stack>
            </Card.Body>
          </Card>
        </div>   
    )
  };

  const DatasetDetails = (props) => {
    if (props.dataset_desc.length > 0)
    {
      return(
        <>
        <div className='m-3'>
        <Row className='mt-1'>
          <Col xs={3} sm={3} md={6} lg={2} xl={2}>
            <strong>Published:</strong>
          </Col>
          <Col xs={6} sm={6} md={6} lg={10} xl={10}>
            {props.dataset_desc[0].publish_date.slice(0,10)}
          </Col>
        </Row>

        <Row className='mt-1'>
          <Col xs={3} sm={3} md={6} lg={2} xl={2}>
            <strong>Contact:</strong>
          </Col>
          
          <Col xs={6} sm={6} md={6} lg={10} xl={10}>
          <span> <a href={`mailto:${props.dataset_desc[0].contact}`} >
            {props.dataset_desc[0].contact}
            </a>
            </span>
          </Col>
        </Row>
        
        <Row className='mt-1'>
          <Col xs={3} sm={3} md={6} lg={2} xl={2}>
            <strong>Dataset:</strong> 
          </Col>
          <Col xs={6} sm={6} md={6} lg={10} xl={10}>
            <span> {props.dataset_desc[0].title}</span>
          </Col>
        </Row>

        <Row className='mt-1'>
        <Col xs={3} sm={3} md={6} lg={2} xl={2}>
            <strong>Tags:</strong> 
          </Col>
          <Col xs={9} sm={9} md={6} lg={10} xl={10} className='d-flex justify-content-start'>
            {            
              (props.dataset_desc[0].tag).split(",").map( (t, index) => {                
                return (<Badge className="me-2 mt-1 ps-2 pe-2" key={t + "_" + index} bg="dark">{t}</Badge>)
              })
            }                      
          </Col>
        </Row>
        </div>        
        </>
      )
    }  
    else {
      return(<>
        <div>No Dataset details</div>
      </>)
    }  
  }

  const props = useSpring({
    from: { x : -50, y : 0, opacity: 0.5 },
    to: { x : 0, y: 0, opacity : 1 },    
    config: {
      mass: 5,
      friction: 50,
      tension: 270,
      precision: 0.0001,
    },
  })  

  return(
      <>      
      <HeaderNew CartCount={cart ? cart.length : 0} />
      
      <div style={{zIndex : 0, touchAction : "pan-y"}}>      
          <Row className="justify-content-center">            
            <Col xs={10} sm={10} md={10} lg={8} xl={7}>
              { 
                ((dataset.length > 0) && (dataset[0] !== null)) || (!!location.state)
                    ? 
                    <>
                      <Row>
                        <Col className='mt-4' >
                          <DatasetInfo viewing_dataset={location.state ? location.state.dataset : dataset}></DatasetInfo>
                        </Col>
                      </Row>

                      <Tabs defaultActiveKey="data" id="uncontrolled-data-listing-tab">
                        <Tab className="" eventKey="data" title="Data">
                          <Stack direction="horizontal" className="p-2">
                            <FontAwesomeIcon className="" size="1x" icon={icon({name: 'magnifying-glass'})} />
                              <Form.Control
                                className="shadow-none ms-1 me-1 ps-1 pe-1"
                                size="sm"
                                type="text"
                                placeholder="Search..."
                                onChange={handleFilterChange}
                                value={filteredInput}
                                style={{ borderRadius:'0px' , border: 'none', borderBottom : '1px gray solid' }}
                              />
                          </Stack>
                          <DataList />                        
                        </Tab>
                        {/*<Tab className="" eventKey="extra_data" title="Supplementary Data"> */}
                          {/*<DataList category_filter="extra"></DataList>*/} {/* use any string other than `main` - `non-main` to get all the data with category other than `main` */}
                        {/*</Tab>*/}
                        <Tab className="" eventKey="citation" title="Citation">
                          <CitationDisplay data_desc_md={location.state ? location.state.dataset : dataset} />
                        </Tab>
                        <Tab className="" eventKey="details" title="Details">
                          <DatasetDetails dataset_desc={location.state ? location.state.dataset : dataset} />
                        </Tab>
                      </Tabs>      
                    </>
                    : 
                      Object.keys(dataset).includes("message")
                      ? handleNonExistenceDS()
                      : <></>
              }          
            </Col>
          </Row>
        {/*</animated.div>*/}
      </div>
    </>
  )

};

export default ViewDatasetByName;