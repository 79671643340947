import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';


import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { api_config } from "./api_url";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import "./dataInfo.css"

const loggedInUser = localStorage.getItem("user");

const DataInfo = ({dsdinfo, session, updateCart, cart}) => {
    const api_url = api_config.url.API_URL
    const params = useParams();
    const navigate = useNavigate();    
    const location = useLocation();
    const [dsdInfo, setDsdInfo] = useState([]);   
    const [user, setUser]  = useState();
    const [isLoading, setIsloading] = useState(true)
            
    const memoizedResult = useMemo(() => {        
        return dsdinfo
    }, []);

    /* Get Data Info*/
    const getDataInfoByDataName = async () => {    
    return new Promise(async (resolve, reject) => {
      setIsloading(true)
      fetch(api_url + "/data/" + params.data_name + "/metadata", {
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => res.json())
      .then(function (myJson) {        
        setIsloading(false)
        resolve(myJson.data);
      });
    });
    };
  
    /* Get Dataset Info*/
    const getDatasetInfoByDataName = async () => {
        return new Promise(async (resolve, reject) => {        
        setIsloading(true)
        fetch(api_url + "/data/" + params.data_name + "/datasets", {
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"                
            },
        })
            .then((res) => res.json())
            .then(function (myJson) {   
                setIsloading(false)              
                resolve(myJson.data);
            });
        });
    };    

    //Call refresh token before access to any resources  
    const refreshToken = () => {    
        return new Promise(async (resolve, reject) => {
        //const refreshToken = getCookie('jwt');        
            fetch(api_url + "/user/refresh", {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'        
                }
        })
        .then((res) => res.json())
        .then((myJson) => {            
            resolve(myJson)
        });
    })    
  }

    useEffect(() => {
        const getDsDInfo = async () => {            
            const datainfo = await getDataInfoByDataName();
            const datasetinfo = await getDatasetInfoByDataName();        
            const validsession = await refreshToken();

            setDsdInfo({dataset : datasetinfo, data: datainfo, user : !!validsession.token })
        }        

        if ( (!!memoizedResult.data === false) || (!!memoizedResult.dataset === false) ) {
            getDsDInfo()
        }
        else
        {            
            setIsloading(false)
        }
    }, [])     

    const handleDownload = (item) => {
        updateCart(item)
    }

    useEffect(() => {}, [cart])

    useEffect(() => {        
        //Set user 
        setUser(JSON.parse(localStorage.getItem("user")))    
    },[])
    
    const DownloadButton = ({viewing_data}) => {
        if (session)
        {        
        //No login user        
        if (!!user !== true) //((!!user !== true) && (Object.keys(viewing_data.user).includes("token") && (!!viewing_data.user.token === false)))
        {
            return(
                <OverlayTrigger            
                    overlay={<Tooltip id="tooltip-disabled">Please login to view the data</Tooltip>}>
                    <span className="d-inline-block">            
                        <Button 
                            style= {{ border : "none"}}
                            className="d-flex align-items-center" 
                            size="lg" 
                            variant="outline-dark" 
                            disabled = {!viewing_data.data.allowed_access}
                            id={viewing_data.data.data_name} 
                        >
                        {/*<img src={prohibited_icon} alt="download prohibited" height="20px" width="20px" />*/}
                        <FontAwesomeIcon size="1x" icon={icon({name: 'lock'})} />
                        </Button>
                    </span>
                </OverlayTrigger>
            )
        }
        //Session expired        
        else if ((!!user === true) && (Object.keys(session).includes("token") && (session.token === false)) )
        {            
            return(
                <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">{session.message}</Tooltip>}>
                    <span className="d-inline-block">            
                        <Button 
                            style= {{ border : "none"}}
                            className="d-flex align-items-center" 
                            size="lg" 
                            variant="outline-dark" 
                            disabled = {!session.token}
                            id={viewing_data.data.data_name} 
                        >
                        {/*<img src={prohibited_icon} alt="download prohibited" height="20px" width="20px" />*/}
                        <FontAwesomeIcon size="1x" icon={icon({name: 'lock'})} />
                        </Button>
                    </span>
                </OverlayTrigger>
            )
        }
        else if ((!viewing_data.data.allowed_access) && (Object.keys(viewing_data.user).includes("token") && (viewing_data.user.token)))
        {
            //Access Denied
            return(
                <OverlayTrigger            
                    overlay={<Tooltip id="tooltip-disabled">                        
                        {viewing_data.data.message}        
                    </Tooltip>}>
                    <span className="d-inline-block">            
                        <Button 
                            style= {{ border : "none"}}
                            className="d-flex align-items-center" 
                            size="lg" 
                            variant="outline-dark" 
                            disabled = {!viewing_data.data.allowed_access}
                            id={viewing_data.data.data_name} 
                        ><FontAwesomeIcon size="1x" icon={icon({name: 'lock'})} />
                        {/*<img src={prohibited_icon} alt="download prohibited" height="20px" width="20px" />*/}
                        </Button>
                    </span>
                </OverlayTrigger>
            )
        }
        if (viewing_data.data.allowed_access)
        {            
            return(
                <Button
                    style= {{ border : "none"}}
                    className="d-flex align-items-center"
                    size="lg" 
                    variant=""
                    disabled = {!viewing_data.data.allowed_access}
                    id={viewing_data.data.data_name}
                    onClick={() => handleDownload({ID : viewing_data.data.data_id , supp_info : viewing_data.data})}
                >
                {
                cart.find(cartItem => cartItem.ID ===  viewing_data.data.data_id)
                    ? <OverlayTrigger            
                    overlay={<Tooltip id="tooltip-disabled">
                      Remove {viewing_data.data.data_name} from download cart
                  </Tooltip>}>
                  <FontAwesomeIcon size="lg" icon={icon({name: 'circle-minus'})} /> 
                </OverlayTrigger>
                    : <OverlayTrigger            
                    overlay={<Tooltip id="tooltip-disabled">
                      Add {viewing_data.data.data_name} from download cart
                  </Tooltip>}>
                  <FontAwesomeIcon size="lg" icon={icon({name: 'circle-plus'})} /> 
                </OverlayTrigger>
                    
                    
                }
                </Button>
            )
        }  
    }     
    }
    

    //console.log(memoizedResult, dsdInfo, session)

    if ( (((!!memoizedResult.data) && (!!memoizedResult.dataset)) || ((!!dsdInfo.data) && (!!dsdInfo.dataset))))
    {        
    return (
       <>
            <div className="ms-1 me-1 mt-2 mb-4">
            <Row className='mb-2'>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex align-items-center justify-content-start dataInfo-title' >
                    {!!memoizedResult.data
                            ? memoizedResult.data.title
                                ? memoizedResult.data.title
                                : memoizedResult.data.data_name
                            : dsdInfo.data.title
                                ? dsdInfo.data.title
                                : dsdInfo.data.data_name
                        }    
                </Col>                    
            </Row>   

            <Row className='mb-2'>
                <Col xs={12} sm={12} md={3} lg={3} xl={3} className='d-flex align-items-center justify-content-start dataInfo-publish-date' >
                    <div >
                        <FontAwesomeIcon size="1x" icon={icon({name: 'calendar', style:'regular'})} />
                            <span> {
                                !!memoizedResult.data
                                    ? memoizedResult.data.publish_date.slice(0,10)
                                    : dsdInfo.data.publish_date.slice(0,10)
                            }</span>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={5} lg={5} xl={5} className='d-flex align-items-center justify-content-start' >
                        <a href={
                                !!memoizedResult.data
                                    ? `mailto:${memoizedResult.data.contact}`
                                    : `mailto:${dsdInfo.data.contact}`
                            } className='datasetInfo-contact'>
                            <FontAwesomeIcon size="1x" icon={icon( {name : "envelope", style:'regular'} )} />        
                            <span>  {
                                !!memoizedResult.data
                                    ? memoizedResult.data.contact
                                    : dsdInfo.data.contact
                            }
                            </span>
                        </a>                        
                        
                    </Col>
                </Row>                
                       
            <Row className='pt-2'>            
                <Col xs={10} sm={10} md={11} lg={11} className='text-start pt-1 pb-1'>
                    {/*Data description*/}                                        
                    <ReactMarkdown 
                        className='dataInfo-md-content'
                        remarkPlugins={[remarkGfm]}
                        children={
                            memoizedResult.data 
                                ? memoizedResult.data.data_desc 
                                : dsdInfo.data.data_desc
                        }
                    >                        
                    </ReactMarkdown>
                </Col>

                <Col xs={2} sm={2} md={1} lg={1} className='d-flex justify-content-center align-items-center'>
                    <DownloadButton viewing_data={!!memoizedResult && !!memoizedResult.data ? memoizedResult : dsdInfo } />
                </Col>                
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                {
                    (!!memoizedResult.data
                        ? memoizedResult.data.tag                         
                        : dsdInfo.data.tag                          
                    ).split(",").map( (t, index) => {                        
                        return (<Badge className="me-2 mt-1 ps-2 pe-2" key={t + "_" + index} bg="dark">{t}</Badge>)
                    })
                }
                </Col>
            </Row>

            </div>
            
        </>        
    )
    }
    else {        
        return(
            isLoading
            ? <>
                <div className="d-flex justify-content-center" style={{ marginTop : "50px"}}>
                  <Spinner animation="border" role="status" >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  </div>
                </>
            : <></>
        )
    }
}

export default DataInfo